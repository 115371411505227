import axios from "axios";
import Cookies from "js-cookie";
// import { getItem } from '../../utility/localStorageControl';

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

const authHeader = () => ({
  Authorization: `Bearer ${Cookies.get("access_token")}`,
  // branchId: Cookies.get('branchId'),
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${Cookies.get("access_token")}`,
    "Content-Type": "application/json",
  },
});

class DataService {
  static get(path = "", optionalHeader = {}) {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: {
        ...authHeader(),
        ...optionalHeader,
      },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = "") {
    return client({
      method: "DELETE",
      url: path,
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${Cookies.get("access_token")}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;

    if (response) {
      if (response.status === 404) {
        // do something here
        /* eslint-disable  */
        return response;
      } else if (response.status === 500) {
        return response;
      } else if (response.status === 401) {
        Cookies.remove("logedIn");
        Cookies.remove("access_token");
        Cookies.remove("userName");
        Cookies.remove("isAdmin");
        window.location.href = "/";
      } else {
        return response;
      }
    }
    return Promise.reject(error);
  }
);
export { DataService };
