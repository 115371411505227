import Cookies from "js-cookie";
import actions from "./actions";

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  ALL_USERS_BEGIN,
  ALL_USERS_SUCCESS,
  ALL_USERS_ERR,
  ALL_USERS_QUERIES_BEGIN,
  ALL_USERS_QUERIES_SUCCESS,
  ALL_USERS_QUERIES_ERR,
  USER_REGISTRATION_BEGIN,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_ERR,
  NOTICES_BEGIN,
  NOTICES_SUCCESS,
  NOTICES_ERR,
} = actions;

const initState = {
  login: Cookies.get("logedIn", false),
  isAdmin: Cookies.get("isAdmin"),
  userName: Cookies.get("userName"),
  allUsers: [],
  notices: [],
  allUserQueries: [],
  registrationData: {},
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_REGISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationData: data,
        loading: false,
      };
    case USER_REGISTRATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
        isAdmin: data.user.type === "admin" ? true : false,
        userName: (
          data.user.first_name.charAt(0).toUpperCase() +
          data.user.first_name.slice(1)
        ).concat(" ", data.user.last_name),
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        userName: "",
        isAdmin: false,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
        isAdmin: data.user.type === "admin" ? true : false,
        userName: (
          data.user.first_name.charAt(0).toUpperCase() +
          data.user.first_name.slice(1)
        ).concat(" ", data.user.last_name),
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case NOTICES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NOTICES_SUCCESS:
      return {
        ...state,
        notices: data,
        loading: false,
      };
    case NOTICES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ALL_USERS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: data,
        loading: false,
      };
    case ALL_USERS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ALL_USERS_QUERIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ALL_USERS_QUERIES_SUCCESS:
      return {
        ...state,
        allUserQueries: data,
        loading: false,
      };
    case ALL_USERS_QUERIES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
