import React, { useEffect, useState } from "react";
import NoticeBoardTable from "../../components/bootstrapTable/noticeBoardTable";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNotice,
  getAllNotices,
} from "../../redux/user authentication/actionCreator";
import { Button, Modal, Form } from "react-bootstrap";
import NoticesView from "./noticesView";

export const NoticeBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showAddNoticeModal, setShowAddNoticeModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getAllNotices());
  }, []);

  const { notices, isAdmin } = useSelector((states) => {
    return {
      notices: states.auth.notices.filter(
        (notice) => notice.status !== "deleted"
      ),
      isAdmin: states.auth.isAdmin === undefined ? false : states.auth.isAdmin,
    };
  });

  const handleAddNewNotice = () => {
    const values = {
      title: title,
      description: description,
    };
    dispatch(
      AddNotice(values, (res) => {
        if (res.status === 200) {
          handleClose();
          setDescription("");
          setTitle("");
        }
      })
    );
  };

  const handleClose = () => {
    setShowAddNoticeModal(!showAddNoticeModal);
  };

  return (
    <>
      <div className="pt-5"></div>
      <section className="gen-section-padding-3 mt-5" id="top">
        <div className="container">
          <div className="d-flex justify-content-between p-3 border-bottom mb-5 border-secondary rounded">
            <h5>Notice Board</h5>
            {["true", true].includes(isAdmin) && (
              <Button
                variant="link"
                className="text-secondary border rounded"
                onClick={() => setShowAddNoticeModal(true)}
              >
                <i className="fa fa-plus"></i> Add New
              </Button>
            )}
          </div>
          <div className="row justify-content-center">
            {/* <div className="col-lg-6"> */}

            {/* <NoticeBoardTable
              tableData={notices}
              // handleStatusUpdate={handleStatusUpdate}
              // handleView={handleView}
              // handleDownload={handleDownload}
              // handleEdit={handleEdit}
              // handleViewPayment={handleViewPayment}
            /> */}
          </div>
          <NoticesView  tableData={notices}/>
        </div>
        {/* </div> */}
      </section>
      <div id="back-to-top">
        <a className="top" id="top" href="#top">
          {" "}
          <i className="ion-ios-arrow-up"></i>
        </a>
      </div>
      <Modal show={showAddNoticeModal} onHide={handleClose} className="">
        <Modal.Body className="bg-dark text-center">
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="bg-dark border-0 text-center justify-content-center">
          <Button
            variant="secondary"
            // className="btn-danger"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="secondary"
            className="btn-danger"
            onClick={handleAddNewNotice}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
