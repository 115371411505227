import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import moment from 'moment';

const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const charRegx = /^[a-zA-Z]+$/; // characters regular expression

const charRegxWithSpaces = /^[a-zA-Z ]+$/; // characters regular expression with spaces

const charNumRegxWithSpaces = /^[a-zA-Z0-9 ]+$/; // characters, numbers regular expression with spaces
const charNumRegxWithSpecific = /^[a-zA-Z0-9\s.,+]+$/; // characters, numbers regular expression with spaces

const charNumRegxWithHypen = /^[A-Za-z0-9-]+$/; // characters, numbers regular expression with hypen

// const charNumRegx = /^[a-zA-Z0-9]+$/; // characters regular expression

const onlyNumberRegx = /^[0-9\b]+$/; // number regular expression

const capitalCharsAndNumbersRegx = /^[A-Z0-9]+$/; // capital characters and numbers regular expression

const CharsAndNumbersRegx = /^[a-zA-Z0-9]+$/; // capital characters and numbers regular expression

const CharWithSpecific = /^[a-zA-Z-_]+$/; // characters and underscore and hypen regular expression
const allCharsWithSpecific = /^[a-zA-Z\s&]+$/; // characters and space and & regular expression

const PhoneNumberReg = /^\d{10}$/; // telephone or fax number regular expression

const costReg = /^\d{7}$/; // cost or fax number regular expression


const mailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/; // email regular expression

// Allow only characters
export const handleKeyPressCharacters = (event) => {
  const { key } = event;
  const pattern = charRegx;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow only characters and spaces
export const handleKeyPressCharactersAndSpaces = (event) => {
  const { key } = event;
  const pattern = charRegxWithSpaces;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};
// Allow only characters and numbers no spl charecters
export const handleKeySplCharacters = (event) => {
  const { key } = event;
  const pattern = /^[a-zA-Z0-9_\s]+$/;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow only characters, numbers and spaces
export const handleKeyPressCharactersNumbersAndSpaces = (event) => {
  const { key } = event;
  const pattern = charNumRegxWithSpaces;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow only characters, numbers and specifics
export const handleKeyPressCharactersNumbersAndSpecifics = (event) => {
  const { key } = event;
  const pattern = charNumRegxWithSpecific;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow only characters, numbers and spaces
export const handleKeyPressCharactersNumbersAndHypens = (event) => {
  const { key } = event;
  const pattern = charNumRegxWithHypen;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow only numeric input and backspace
export const handleKeyPressNumbers = (event) => {
  const { key } = event;
  const pattern = onlyNumberRegx;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow only capital letters and numbers
export const handleKeyPressCapitalCharsAndNumbers = (event) => {
  const { key } = event;
  const pattern = capitalCharsAndNumbersRegx;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow  letters and numbers
export const handleKeyPressCharsAndNumbers = (event) => {
  const { key } = event;
  const pattern = CharsAndNumbersRegx;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow  chars and specifics
export const handleKeyPressCharsAndSpecifics = (event) => {
  const { key } = event;
  const pattern = CharWithSpecific;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

// Allow  chars , space, and
export const handleKeyPressAllCharsAndSpecifics = (event) => {
  const { key } = event;
  const pattern = allCharsWithSpecific;

  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

/**  ********************************************
    Function to display Success message
********************************************  */
export const successToast = (res) =>
  toast.success(res, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    toastId: 'success1'
  });

/**  ********************************************
    Function to display Error message
********************************************  */
export const errorToast = (res) =>
  toast.error(res, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    toastId: "123",
  });

/**  ********************************************
    Function to display Warning message
********************************************  */
export const warningToast = (res) =>
  toast.warn(res, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

/**  ********************************************
    Function to check Object has empty keys or not
********************************************  */
  export function hasEmptyValue(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (!value || value.trim() === "") {
          return key; // Key is empty
        }
      }
    }
    return false; // No empty keys found
  }
