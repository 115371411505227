import React, { useState } from "react";
import { errorToast, warningToast } from "../../utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { DocumentUploadToS3 } from "../../utils/awsServices";
import { uploadIndividualVideoPaymentDetails } from "../../redux/videos/actionCreator";
import Cookies from "js-cookie";

export const PaymentDetails = ({handleNext }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [progressModal, setProgressModal] = useState(false);

  const handlePostURL = async () => {
    const fileInput = document.getElementById("formPaymentFile");
    const file = fileInput.files[0]; // Get the first selected file
    let isSubmit = true;

    let videoObject = {
      payment_status: "paid",
    };
    if (file) {
      // You can proceed to upload the file to your server or perform other actions.
      // For example, you can pass the 'file' object to your 'DocumentUploadToS3' function.
      if (file != "") {
        const paymentProofLink = await DocumentUploadToS3(
          file,
          "short-films-payment-images",
          setProgress,
          setProgressModal
        );
        videoObject.payment_proof = paymentProofLink.Location;
      }
    } else {
      // No file selected
      errorToast("Select a File");
      isSubmit = false;
    }
    if (isSubmit) {
      const registrationId = Cookies.get("registration_id");
      dispatch(
        uploadIndividualVideoPaymentDetails(
          registrationId,
          videoObject,
          (response) => {
            if (response.status === 200) handleNext();
            // navigate("/my-videos");
          }
        )
      );
    } else {
      //   warningToast(
      //     `Please upload a Image}`
      //   );
    }
  };

  return (
    <>
      <div class="bg-info-dark p-5">
        <h4 class="mb-2 text-center">Payment Details</h4>
        {/* <p class="mb-2 fs-6">
          <ul>
            <li>Bank Name : State bank of india,</li>
            <li>Account Number : 3420XX01242,</li>
            <li>IFSC code : SBIN000000814, </li>
            <li>Branch : Hyderabad.</li>
          </ul>
        </p> */}
        <p class="mb-2 text-center fs-6">Scan below for online Payment.</p>
        <p class="mb-2 text-center fs-6">UPI ID : theaviatorstudio@ibl</p>
        <h3 className="text-center mb-2"> Amount <span className="text-danger">₹ 1,000.00</span> </h3>
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <div className="bg-white p-3 d-inline-block">
              <img alt="scanner" src="/assets/images/Aviator-studio.jpeg" />
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        Proof of Screenshot{" "}
        <input
          type="file"
          id="formPaymentFile"
          className="form-control cursor-pointer mb-3"
          accept="image/png, image/jpeg,video/mp4"
          // onChange={(e) => handleAddImages(e)}
        />
      </div>
      <div className="text-center">
        <button
          className="btn btn-danger mx-auto"
          onClick={handlePostURL}
          type="button"
        >
          Submit
        </button>
      </div>
      
    </>
  );
};
