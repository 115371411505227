import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import QueriesView from "./queriesView";
import { getAllUserQueries } from "../../redux/user authentication/actionCreator";

export const UserQueries = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getAllUserQueries());
  }, []);
  const { allUserQueries } = useSelector((state) => {
    return {
      allUserQueries: state.auth.allUserQueries,
    };
  });
  return (
    <>
      <div className="pt-5"></div>
      <section className="gen-section-padding-3 mt-5" id="top">
        <div className="container">
          <div className="d-flex justify-content-between p-3 border-bottom mb-5 border-secondary rounded">
            <h5>User Enquiries</h5>
          </div>
          <div className="row justify-content-center">
            {/* <div className="col-lg-6"> */}
          </div>
          <QueriesView tableData={allUserQueries} />
        </div>
        {/* </div> */}
      </section>
      <div id="back-to-top">
        <a className="top" id="top" href="#top">
          {" "}
          <i className="ion-ios-arrow-up"></i>
        </a>
      </div>
    </>
  );
};
