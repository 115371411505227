const actions = {
  USER_REGISTRATION_BEGIN: "USER_REGISTRATION_BEGIN",
  USER_REGISTRATION_SUCCESS: "USER_REGISTRATION_SUCCESS",
  USER_REGISTRATION_ERR: "USER_REGISTRATION_ERR",

  LOGIN_BEGIN: "LOGIN_BEGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERR: "LOGIN_ERR",

  LOGOUT_BEGIN: "LOGOUT_BEGIN",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERR: "LOGOUT_ERR",

  NOTICES_BEGIN: "NOTICES_BEGIN",
  NOTICES_SUCCESS: "NOTICES_SUCCESS",
  NOTICES_ERR: "NOTICES_ERR",

  ALL_USERS_BEGIN: "ALL_USERS_BEGIN",
  ALL_USERS_SUCCESS: "ALL_USERS_SUCCESS",
  ALL_USERS_ERR: "ALL_USERS_ERR",

  ALL_USERS_QUERIES_BEGIN: "ALL_USERS_QUERIES_BEGIN",
  ALL_USERS_QUERIES_SUCCESS: "ALL_USERS_QUERIES_SUCCESS",
  ALL_USERS_QUERIES_ERR: "ALL_USERS_QUERIES_ERR",

  userRegistrationBegin: () => {
    return {
      type: actions.USER_REGISTRATION_BEGIN,
    };
  },

  userRegistrationSuccess: (data) => {
    return {
      type: actions.USER_REGISTRATION_SUCCESS,
      data,
    };
  },

  userRegistrationErr: (err) => {
    return {
      type: actions.USER_REGISTRATION_ERR,
      err,
    };
  },

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
  getAllNoticesBegin: () => {
    return {
      type: actions.NOTICES_BEGIN,
    };
  },
  getAllNoticesSuccess: (data) => {
    return {
      type: actions.NOTICES_SUCCESS,
      data,
    };
  },

  getAllNoticesErr: (err) => {
    return {
      type: actions.NOTICES_ERR,
      err,
    };
  },
  getAllUsersBegin: () => {
    return {
      type: actions.ALL_USERS_BEGIN,
    };
  },
  getAllUsersSuccess: (data) => {
    return {
      type: actions.ALL_USERS_SUCCESS,
      data,
    };
  },

  getAllUsersErr: (err) => {
    return {
      type: actions.ALL_USERS_ERR,
      err,
    };
  },
  getAllUsersQueriesBegin: () => {
    return {
      type: actions.ALL_USERS_QUERIES_BEGIN,
    };
  },
  getAllUsersQueriesSuccess: (data) => {
    return {
      type: actions.ALL_USERS_QUERIES_SUCCESS,
      data,
    };
  },

  getAllUsersQueriesErr: (err) => {
    return {
      type: actions.ALL_USERS_QUERIES_ERR,
      err,
    };
  },
};

export default actions;
