import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { handleKeyPressNumbers } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import { updateIndividualVideo } from "../../redux/videos/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { generateAWSImagesURL } from "../../utils/awsServices";

export const VideoEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { videoDetails } = location.state;
  const [editFormData, setEditFormData] = useState({});
  const [crewImages, setCrewImages] = useState([]);
  const numberOfOptions = 15; // duration of minutes

  useEffect(() => {
    setEditFormData({ ...videoDetails });
    console.log(videoDetails);

    console.log(videoDetails.crew_images_url);

    const fetchData = async () => {
      // Assuming videoDetails.crew_images_url is an array of S3 object URLs
      let imagesArr = JSON.parse(videoDetails.crew_images_url);
      const presignedUrls = await generateAWSImagesURL(imagesArr);
      console.log("presignedUrls", presignedUrls);
      setCrewImages(presignedUrls);
      // Do something with the generated presigned URLs, e.g., update state
    };

    fetchData();
  }, [videoDetails]);

  const { isAdmin } = useSelector((states) => {
    return {
      isAdmin: states.auth.isAdmin === undefined ? false : states.auth.isAdmin,
    };
  });

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    let newObj = { ...editFormData };
    newObj[name] = value;
    setEditFormData(newObj);
  };

  const handlePostEdit = (e) => {
    e.preventDefault();
    dispatch(
      updateIndividualVideo(editFormData.video_id, editFormData, (res) => {
        if (res.status === 200) {
          if (isAdmin === true) navigate("/");
          else navigate("/my-videos");
        }
      })
    );
  };

  return (
    <>
      <div class="pt-5"></div>
      <section class="gen-section-padding-3 mt-5">
        <div class="container">
          <Form onSubmit={handlePostEdit}>
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                placeholder="Title"
                name="title"
                value={editFormData.title}
                onChange={handleChangeInput}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Video Synopsis</Form.Label>
              <Form.Control
                as="textarea"
                name="video_synopsis"
                rows={3}
                value={editFormData.video_synopsis}
                onChange={handleChangeInput}
              />
            </Form.Group>

            <Row className="mb-3">
              <Col>
                <Form.Label>Duration</Form.Label>

                <Form.Select
                  defaultValue="Duration..."
                  name="duration"
                  value={editFormData.duration}
                  onChange={handleChangeInput}
                >
                  <option hidden>Select Duration</option>
                  {[...Array(numberOfOptions).keys()].map((index) => (
                    <option
                      key={index + 1}
                      value={String(index + 1).padStart(2, "0")}
                    >
                      {`${String(index + 1).padStart(2, "0")} Mins`}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>Genre</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="genre"
                    value={editFormData.genre}
                    onChange={handleChangeInput}
                  >
                    <option hidden>Select Genre</option>
                    <option value="action">Action</option>
                    <option value="adventure">Adventure</option>
                    <option value="comedy">Comedy</option>
                    <option value="drama">Drama</option>
                    <option value="fantasy">Fantasy</option>
                    <option value="horror">Horror</option>
                    <option value="musicals">Musicals</option>
                    <option value="mystery">Mystery</option>
                    <option value="romance">Romance</option>
                    <option value="science-fiction">Science Fiction</option>
                    <option value="sports">Sports</option>
                    <option value="thriller">Thriller</option>
                    <option value="Western">Western</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Label>Cast</Form.Label>
              <Form.Control
                placeholder="Cast"
                name="cast"
                value={editFormData.cast}
                onChange={handleChangeInput}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Label>Crew</Form.Label>
              <Form.Control
                placeholder="Crew"
                name="crew"
                value={editFormData.crew}
                onChange={handleChangeInput}
              />
            </Form.Group>
            <div className="row mx-0 mb-4">
              {crewImages.length > 0 &&
                crewImages.map((list, index) => {
                  return (
                    <>
                      <div className="position-relative col-lg-2 col-md-2">
                        <span
                          className="mb- borde shadow cursor-pointer"
                          key={index}
                        >
                          {" "}
                          <div className="mb-3 editicon bg-gray-400 position-relative symbol symbol-175px bg-white pt- w-100">
                            <>
                              <span
                                className="close-btn"
                                onClick={() => {
                                  // HandleDeleteLayoutImage(index);
                                }}
                              >
                                <i className="fa fa-times text-dark"></i>
                              </span>
                              <a href={list} target="_blank">
                                <img src={list} className="w-100" />
                              </a>
                            </>
                          </div>
                        </span>
                      </div>
                      <img src={list} className="w-100" />
                    </>
                  );
                })}
            </div>
            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Label>Equipment Used</Form.Label>
              <Form.Control
                placeholder="Equipment"
                name="camera_equipment"
                value={editFormData.camera_equipment}
                onChange={handleChangeInput}
              />
            </Form.Group>

            {["true", true].includes(isAdmin) && (
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Youtube URL</Form.Label>
                  <Form.Control
                    name="youtube_url"
                    value={editFormData.youtube_url}
                    onChange={handleChangeInput}
                  />
                </Form.Group>

                {/* <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control />
            </Form.Group> */}
              </Row>
            )}
            <Button variant="primary" className="mt-2" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
};
