import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/user authentication/actionCreator";
import { useDispatch } from "react-redux";
import { warningToast } from "../../utils";
import Cookies from "js-cookie";

export const Login = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // useCallback(
    //   (values) => {
    //     dispatch(login(values, () => history('/admin')));
    //   },
    //   [history, dispatch],
    // );

    const phoneNumber = event.target.phoneNumber.value;
    const password = event.target.password.value;

    // Do something with the form values, such as sending a request to your server

    const credentials = {
      phone: phoneNumber,
      password: password,
    };

    const isSubmit = hasEmptyValue(credentials);

    if (!isSubmit) {
      dispatch(
        login(credentials, (res) => {
          if (res.user.type === "admin") {
            history("/");
          } else {
            if (res.user.video_upload_status === "none"){
              history("/upload-video");
              Cookies.set("counter", 0);
            }
            else if (res.user.video_upload_status === "payment-due") {
              history("/upload-video");
              Cookies.set("counter", 1);
            } else history("/my-videos");
          }
        })
      );
    } else {
      warningToast(
        `Please fill ${isSubmit.charAt(0).toUpperCase() + isSubmit.slice(1)}`
      );
    }

    // Optionally, you can reset the form fields
    // event.target.reset();
  };

  function hasEmptyValue(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (!value || value.trim() === "") {
          return key; // Key is empty
        }
      }
    }
    return false; // No empty keys found
  }

  return (
    <>
      <section className="position-relative pb-0">
        <div
          className="gen-login-page-background"
          style={{
            backgroundImage: `url('assets/images/background/asset-54.jpg')`,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <form
                  onSubmit={handleSubmit}
                  name="pms_login"
                  id="pms_login"
                  // action="user-login"
                  // method="post"
                >
                  <h4>
                    Sign In
                    <Link to="/" className="login-submit">
                      {/* <a href="index.html"> */}
                      <i className="ion-android-close float-icon"></i>
                    </Link>
                  </h4>
                  <p className="login-username">
                    <label for="user_login">Phone Number or Email</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="input"
                      // value
                      size="20"
                    />
                  </p>
                  <p className="login-password">
                    <label for="user_pass">Password</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="input"
                      // value
                      size="20"
                    />
                  </p>
                  <p className="login-remember">
                    <label>
                      <input
                        name="rememberme"
                        type="checkbox"
                        id="rememberme"
                        value="forever"
                      />
                      Remember Me{" "}
                    </label>
                  </p>
                  {/* <p className="login-submit">
                    <input
                      type="submit"
                      name="wp-submit"
                      id="wp-submit"
                      className="button button-primary"
                      value="Log In"
                    />
                    <input type="hidden" name="redirect_to" />
                  </p> */}
                  {/* <Link to="/videos-upload" className="login-submit"> */}
                  <button type="submit" className="gen-button me-2">
                    Log In
                  </button>
                  {/* </Link> */}
                  {/* <input type="hidden" name="pms_login" value="1" />
                  <input type="hidden" name="pms_redirect" /> */}
                  {/* <Link to="/register" className="me-1">
                    Register
                  </Link> */}
                  {/* |  */}
                  <Link to="recover-password">Lost your password?</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
