import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Cookies from "js-cookie";
import { Button, Modal } from "react-bootstrap";
import { hasEmptyValue, warningToast } from "../../utils";
import { useDispatch } from "react-redux";
import { postUserQueries } from "../../redux/user authentication/actionCreator";

const MainPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    // Scroll to the target section when the location changes
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  const handleQueriesSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    let obj = {
      user_name: event.target.user_name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      subject: event.target.subject.value,
      comments: event.target.comments.value,
    };
    // // useCallback(
    // //   (values) => {
    // //     dispatch(login(values, () => history('/admin')));
    // //   },
    // //   [history, dispatch],
    // // );

    const isSubmit = hasEmptyValue(obj);

    if (!isSubmit) {
      dispatch(
        postUserQueries(obj, (res) => {
          if (res.status === 200) {
            event.target.reset();

            if (res.data.data.user.type === "admin") {
              navigate("/");
            } else {
              navigate("/my-videos");
            }
          }
        })
      );
    } else {
      warningToast(
        `Please fill ${isSubmit.charAt(0).toUpperCase() + isSubmit.slice(1)}`
      );
    }

    // Optionally, you can reset the form fields
    // event.target.reset();
  };

  const handleCloseModal = () => {
    setShowNotice(!showNotice);
  };

  return (
    <>
      {/* <!--=========== Loader =============--> */}
      {/* <div id="gen-loading">
        <div id="gen-loading-center">
          <img src="assets/images/logo-1.png" alt="loading" />
        </div>
      </div> */}
      {/* <!--=========== Loader =============--> */}
      {/* <!-- owl-carousel Banner Start --> */}
      <section className="pt-0 pb-0" id="top">
        <div className="container-fluid px-0">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="gen-banner-movies banner-style-2">
                <div
                  className="owl-carousel owl-loaded owl-drag"
                  data-dots="false"
                  data-nav="true"
                  data-desk_num="1"
                  data-lap_num="1"
                  data-tab_num="1"
                  data-mob_num="1"
                  data-mob_sm="1"
                  data-autoplay="true"
                  data-loop="true"
                  data-margin="0"
                >
                  <div
                    className="item"
                    style={{
                      backgroundImage: `url('assets/images/background/asset-1.jpg')`,
                    }}
                  >
                    <div className="gen-movie-contain-style-2 h-100">
                      <div className="container h-100">
                        <div className="row flex-row-reverse align-items-center h-100">
                          <div className="col-xl-6"></div>
                          <div className="col-xl-6">
                            <div className="gen-movie-info">
                              <h3>Youtube Stories</h3>
                            </div>
                            <div className="gen-movie-meta-holder mb-10">
                              <p>
                                Join us on this
                                cinematic journey as we invite you to explore
                                our portfolio, discover new stories, and connect
                                with fellow film enthusiasts. Whether you're a
                                seasoned cinephile or simply seeking an escape
                                into the world of storytelling, we're thrilled
                                to share our creative vision with you.
                              </p>
                              <p>
                                We're always on the lookout for talented,
                                creative individuals to join us in bringing
                                these stories to life. If you're passionate
                                about filmmaking and eager to collaborate on
                                exciting projects, we{" "}
                                <strong class="text-danger">invite</strong> you
                                to be a part of our team. Let's create magic
                                together.
                              </p>
                            </div>
                            <div className="gen-movie-action">
                              <div className="gen-btn-containe">
                                {/* <Link
                                  to="pre-login"
                                  className="gen-button .gen-button-dark  me-2"
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fa fa-film "
                                  ></i>
                                  <span className="text">Participate</span>
                                </Link> */}
                                <a
                                  href="#contact"
                                  className="gen-button .gen-button-dark me-2"
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fa fa-film"
                                  ></i>
                                  <span className="text">Contact us</span>
                                </a>

                                {/* <span
                                  to="pre-login"
                                  className="gen-button .gen-button-dark cursor-pointer"
                                  onClick={() => setShowNotice(true)}
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fa fa-award "
                                  ></i>
                                  <span className="text">Awards</span>
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- owl-carousel Banner End --> */}
      <section className="gen-section-padding-2 gen-blog" id="abouttas">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12 d-flex align-items-center">
              <div className="gen-section-title-main">
                <h1 className="gen-section-heading-2 mb-4">About TAS</h1>
                <p>
                  In our journey of versatile experience, we take immense pride
                  in our role as a platform dedicated to fostering and
                  introducing emerging artists from diverse creative
                  backgrounds. Our journey has been marked by a commitment to
                  nurturing talent in various crafts, providing a stage for
                  artists to showcase their unique skills, and creating
                  opportunities for them to shine in the spotlight.
                </p>

                <p>
                  We believe that creativity knows no bounds, and our platform
                  has been a testament to this belief. From budding filmmakers
                  to aspiring painters, we have continuously strived to provide
                  a launchpad for artists of all kinds, allowing them to find
                  their voice, reach a wider audience, and make a lasting impact
                  in the world of art and creativity. Our four-year journey is a
                  testament to our dedication to the arts and our unwavering
                  commitment to nurturing the next generation of talented
                  individuals who are destined to leave their mark in their
                  respective crafts.
                </p>
                <div className="gen-btn-container">
                  {/* <Link
                    to={`${
                      Cookies.get("access_token") ? "/upload-video" : "/login"
                    }`}
                    className="gen-button"
                  >
                    <div className="gen-button-block">
                      <span className="gen-button-line-left"></span>
                      <span className="gen-button-text">Participate Now</span>
                      
                    </div>
                  </Link> */}
                  <a
                                  href="#contact"
                                  className="gen-button .gen-button-dark me-2"
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fa fa-film"
                                  ></i>
                                  <span className="text">Contact us</span>
                                </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12 mt-5 mt-xl-0">
              <div className="gen-image-main">
                <img
                  src="assets/images/blog-img-2.png"
                  className="gen-blog-1"
                  alt="streamlab-image"
                />
                <img
                  src="assets/images/blog-img-3.png"
                  className="gen-blog-2"
                  alt="streamlab-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- owl-carousel images Start --> */}
      <section className="pt-0 gen-section-padding-2 home-singal-silder">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="gen-banner-movies">
                <OwlCarousel className="owl-theme" loop items="1">
                  <Link
                    to="https://www.youtube.com/watch?v=EjW3oR7JIlY"
                    target="_blank"
                  >
                    <div
                      className="item"
                      style={{
                        backgroundImage: `url('assets/images/background/av1.png')`,
                      }}
                    >
                      {" "}
                      <div className="gen-movie-contain h-100">
                        <div className="container h-100">
                          <div className="row align-items-center h-100">
                            <div className="col-xl-6">
                              {/* <div className="gen-movie-info">
                              <h3>Love Unplugged</h3>
                            </div>
                            <div className="gen-movie-meta-holder">
                              <ul>
                                <li>1 Season</li>
                                <li>3 Episode</li>
                                <li>2013</li>
                                <li>
                                  <a href="#">
                                    <span>Comedy</span>
                                  </a>
                                </li>
                              </ul>
                              <p>
                                Streamlab is a long established fact that a
                                reader will be distracted by the readable
                                content of a page when Streamlab at its layout
                                Streamlab.
                              </p>
                            </div> */}
                              {/* <div className="gen-movie-action">
                                <div className="gen-btn-container">
                                  // className="gen-button gen-button-dark"
                                  <span className="text">Play now</span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="https://www.youtube.com/watch?v=EjW3oR7JIlY"
                    target="_blank"
                  >
                    <div
                      className="item"
                      style={{
                        backgroundImage: `url('assets/images/background/av2.png')`,
                      }}
                    >
                      <div className="gen-movie-contain h-100">
                        <div className="container  h-100">
                          <div className="row align-items-center h-100">
                            <div className="col-xl-12">
                              {/* <div className="gen-movie-info">
                              <h3>Kadal Kalave</h3>
                            </div>
                            <div className="gen-movie-meta-holder">
                              <ul>
                                <li>1 Season</li>
                                <li>5 Episode</li>
                                <li>2015 to 2016</li>
                                <li>
                                  <a href="#">
                                    <span>Biography</span>
                                  </a>
                                </li>
                              </ul>
                              <p>
                                Streamlab is a long established fact that a
                                reader will be distracted by the readable
                                content of a page when Streamlab at its layout
                                Streamlab.
                              </p>
                            </div> */}
                              {/* <div className="gen-movie-action">
                                <div className="gen-btn-container button-1">
                                  // className="gen-button gen-button-dark" 
                                  <span className="text">Play now</span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="https://www.youtube.com/watch?v=EjW3oR7JIlY"
                    target="_blank"
                  >
                    <div
                      className="item"
                      style={{
                        backgroundImage: `url('assets/images/background/av3.png')`,
                      }}
                    >
                      <div className="gen-movie-contain h-100">
                        <div className="container  h-100">
                          <div className="row align-items-center h-100">
                            <div className="col-xl-6">
                              <div className="gen-movie-info">
                                {/* <h3>Masti</h3> */}
                              </div>
                              {/* <div className="gen-movie-meta-holder">
                                <ul>
                                  <li>1 Season</li>
                                  <li>1 Episode</li>
                                  <li>2017 to 2018</li>
                                  <li>
                                    <a href="#">
                                      <span>Drama</span>
                                    </a>
                                  </li>
                                </ul>
                                <p>
                                  Streamlab is a long established fact that a
                                  reader will be distracted by the readable
                                  content of a page when Streamlab at its layout
                                  Streamlab.
                                </p>
                              </div> */}
                              {/* <div className="gen-movie-action">
                                <div className="gen-btn-container button-1">
                                  className="gen-button gen-button-dark"
                                  <span className="text">Play now</span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- owl-carousel images End --> */}
      <div id="contact">
        <section className="gen-section-padding-3">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <div className="gen-icon-box-style-1">
                  <div className="gen-icon-box-icon">
                    <span className="gen-icon-animation">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                  </div>
                  <div className="gen-icon-box-content">
                    <h3 className="pt-icon-box-title mb-2">
                      <span>Our Location</span>
                    </h3>
                    <p className="gen-icon-box-description">
                      -{/* <br /> London SE1 7PB, United Kingdom */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 mt-4 mt-md-0">
                <div className="gen-icon-box-style-1">
                  <div className="gen-icon-box-icon">
                    <span className="gen-icon-animation">
                      <i className="fas fa-phone-alt"></i>
                    </span>
                  </div>
                  <div className="gen-icon-box-content">
                    <h3 className="pt-icon-box-title mb-2">
                      <span>call us at</span>
                    </h3>
                    <p className="gen-icon-box-description">
                      + (91) 998-822-7266
                      {/* <br />+ (567) 1234-567-8901 */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-12 mt-4 mt-xl-0">
                <div className="gen-icon-box-style-1">
                  <div className="gen-icon-box-icon">
                    <span className="gen-icon-animation">
                      <i className="far fa-envelope"></i>
                    </span>
                  </div>
                  <div className="gen-icon-box-content">
                    <h3 className="pt-icon-box-title mb-2">
                      <span>Mail us</span>
                    </h3>
                    <p className="gen-icon-box-description">
                      tas.shortfilms@gmail.com
                      <br />
                      {/* tas.shortfilms@gmail.com */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="gen-section-padding-3 pt-0 gen-top-border">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6">
                <h2 className="mb-5">get in touch</h2>
                <form onSubmit={handleQueriesSubmit}>
                  <div className="row gt-form">
                    <div className="col-md-6 mb-4">
                      <input
                        type="text"
                        name="user_name"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <input type="email" name="email" placeholder="Email" />
                    </div>
                    <div className="col-md-12 mb-4">
                      <input
                        type="number"
                        name="phone"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="col-md-12 mb-4">
                      <input type="text" name="subject" placeholder="Subject" />
                    </div>
                    <div className="col-md-12 mb-4">
                      <textarea
                        name="comments"
                        rows={6}
                        placeholder="Your Message"
                        data-gramm="false"
                        wt-ignore-input="true"
                        defaultValue={""}
                      />
                      <br />
                      <input
                        type="submit"
                        defaultValue="Send"
                        className="mt-4"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- Back-to-Top start --> */}
      <div id="back-to-top">
        <a className="top" id="top" href="#top">
          {" "}
          <i className="ion-ios-arrow-up"></i>
        </a>
      </div>
      <Modal show={showNotice} onHide={handleCloseModal} className=" ">
        {/* <Modal.Header closeButton className="bg-dark border-0 text-white">
          <Modal.Title className="">{selectedNotice?.title}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="bg-dark text-cente">
          <div class="bg-info-dar p- mt-5">
            <ul class="list-unstyle ms-5">
              <li> Best actor (male) - 10000</li>
              <li> Best actor (female) - 10000</li>
              <li>Best supporting actor (male) - 10000</li>
              <li>Best supporting actor (female) - 10000</li>
              <li>Best Director - 10000</li>
              <li>Best music director - 10000</li>
              <li>Best screenplay writer - 10000</li>
              <li>Best story - 10000</li>
              <li>Best sound design - 10000</li>
              <li> Best Cinematography - 10000</li>
              <li> Best costumes - 10000</li>
              <li> Best editor - 10000</li>
              <li> Best lyrics - 10000</li>
              <li> Best short film Jury - 20000</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-dark border-0 text-center justify-content-center">
          <Button
            variant="secondary"
            className="btn-danger"
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MainPage;
