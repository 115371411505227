import { combineReducers } from "redux";
import authReducer from "./user authentication/reducers";
import allVideosReducer from "./videos/reducers";

const rootReducers = combineReducers({
  auth: authReducer,
  videos: allVideosReducer,
});

export default rootReducers;
