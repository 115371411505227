import React, { useState } from "react";
import { DocumentUploadToS3 } from "../../utils/awsServices";
import { uploadIndividualVideo } from "../../redux/videos/actionCreator";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { errorToast, warningToast } from "../../utils";
import { Link } from "react-router-dom";

export const VideoUpload = ({
  videoId,
  videoUploadModal,
  setVideoUploadModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // returns current object



  const [progress, setProgress] = useState(0);
  const [progressModal, setProgressModal] = useState(false);

  const handlePostURL = async () => {
    const fileInput = document.getElementById("formFile");
    const file = fileInput.files[0]; // Get the first selected file
    let isSubmit = true;

    let videoObject = {};
    if (file) {
      // You can proceed to upload the file to your server or perform other actions.
      // For example, you can pass the 'file' object to your 'DocumentUploadToS3' function.
      if (file != "") {
        const videoLink = await DocumentUploadToS3(
          file,
          "short-films",
          setProgress,
          setProgressModal
        );
        videoObject.file_url = videoLink.Location;
      }
    } else {
      // No file selected
      errorToast("Select a File");
      isSubmit = false;
    }
    if (!isSubmit) {
      dispatch(
        uploadIndividualVideo(videoId, videoObject, (response) => {
          if (response.status === 200) navigate("/my-videos");
        })
      );
    } else {
      warningToast(
        `Please fill ${isSubmit.charAt(0).toUpperCase() + isSubmit.slice(1)}`
      );
    }
  };

  return (
    <>
      {/* <Modal
        // {...props}
        show={videoUploadModal}
        onHide={() => setVideoUploadModal(false)}
        // style={{ backgroundColor: '#3F3E40' }}
        className="bg-setcolor"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Uploading...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {progressModal && (
            <div className="progress-popup">
              <div className="w-25 text-center">
                <h1>{`${progress.toFixed(2)}%`}</h1>
                <ProgressBar animated now={progress} className="my-3" />
                <p>Uploading Video...</p>
                <div>
                  <button className="btn btn-link text-danger">Cancel</button>
                </div>
              </div>
            </div>
          )}
          <h2 class="mb-5">Upload a Video</h2>
          <div class="col-md-12 mb-4">
            <input
              class="form-control"
              type="file"
              id="formFile"
              name="videoUploadLink"
              accept="video/mp4,video/x-m4v,video/*"
              //   defaultValue={formValues.file_url}
            />
          </div>
          <button
            className="btn btn-danger"
            onClick={handlePostURL}
            type="submit"
          >
            UPLOAD
          </button>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setProgressModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>{" "} */}
      {/* <h2 class="mb-5">Upload a Video</h2>
      <div class="col-md-12 mb-4">
        <input
          class="form-control"
          type="file"
          id="formFile"
          name="videoUploadLink"
          accept="video/mp4,video/x-m4v,video/*"
          defaultValue={formValues.file_url}
        />
      </div>
      <button className="btn btn-danger" onClick={handlePostURL} type="submit">
        UPLOAD
      </button> */}
      <>
        <div class="bg-info-dark p-5">
          <p class="mb-2 fs-4 text-center">
            Currently your Request is under process, you can soon upload your
            video after Admin approves your payment...
          </p>

          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <Link to="/my-videos">
                <div className="p-3 d-inline-block fs-6">
                  Get back to my videos....
                </div>
              </Link>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
