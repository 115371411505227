import Cookies from "js-cookie";
import React, { useState } from "react";
import { errorToast, hasEmptyValue, warningToast } from "../../utils";
import { DocumentUploadToS3 } from "../../utils/awsServices";
import { useDispatch } from "react-redux";
import { uploadVideo } from "../../redux/videos/actionCreator";
import { useNavigate } from "react-router";
import { Modal, Button } from "react-bootstrap";

export const VideoDetails = ({ formValues, handleNext, setVideoId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const numberOfOptions = 15; // duration of minutes

  const [progress, setProgress] = useState(0);
  const [progressModal, setProgressModal] = useState(false);

  const [formInputValues, setFormInputValues] = useState({});

  const [showAlert, setShowAlert] = useState(false);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const [imagesArray, setImagesArray] = useState([]);

  const handleAddImages = (event) => {
    // Get the selected file(s) from the input element
    const selectedFiles = event.target.files;

    // Create a copy of the current state
    const newArray = [...imagesArray];

    // Push each file from the selectedFiles array to the copy
    for (let i = 0; i < selectedFiles.length; i++) {
      newArray.push(selectedFiles[i]);
    }

    // Set the state with the updated array
    setImagesArray(newArray);
  };

  /**  ********************************************
   Function to update deleted aircraft layout files
   ********************************************  */
  const HandleDeleteLayoutImage = (ind) => {
    let arrayCopy = [...imagesArray];
    let deletedImageArray = arrayCopy.filter((list, index) => {
      if (index == ind) {
        return false;
      }
      return true;
    });
    setImagesArray(deletedImageArray);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // 👈️ prevent page refresh

    const imageUrls = [];
    let isSubmit = true;
    for (const imageFile of imagesArray) {
      const imageLink = await DocumentUploadToS3(
        imageFile,
        "short-films-crew-images", // S3 folder or path
        setProgress,
        setProgressModal
      );

      if (imageLink && imageLink.Location) {
        imageUrls.push(imageLink.Location);
      } else {
        // Handle the case where the upload failed for a specific image
        console.error("Please Upload image:", imageFile.name);
        isSubmit = false;
      }
    }
    setFormInputValues((prevFormInputValues) => ({
      ...prevFormInputValues,
      crew_images_url: JSON.stringify(imageUrls),
    }));
    let videoObj = { ...formInputValues };

    videoObj.crew_images_url = JSON.stringify(imageUrls);

    // Now, 'imageUrls' contains the URLs of the uploaded images.

    const emptyKey = hasEmptyValue(videoObj);

    if (emptyKey) {
      errorToast(`Enter '${emptyKey}'.`);
    } else {
      if (isSubmit) {
        dispatch(
          uploadVideo(videoObj, (response) => {
            if (response.status === 200)
              //   navigate("/my-videos");
              setVideoId(response.videoId);
            handleNext();
          })
        );
      } else {
        warningToast(
          `Please fill ${emptyKey.charAt(0).toUpperCase() + emptyKey.slice(1)}`
        );
      }
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // 👈️ prevent page refresh

    let newObj = {
      registration_id: Cookies.get("registration_id"),
      title: event.target.title.value,
      duration: event.target.duration.value,
      genre: event.target.genre.value,
      video_synopsis: event.target.video_synopsis.value,
      cast: event.target.cast.value,
      crew: event.target.crew.value,
      camera_equipment: event.target.equipment_used.value,
    };

    setFormInputValues(newObj);
  };

  return (
    <>
      <span className="text-center">
        <h2 class="mb-5 mx-auto">Fill Short Film details</h2>
      </span>
      <form onSubmit={handleFormSubmit}>
        <div class="row gt-form">
          <div class="col-md-12 mb-4">
            <input
              type="text"
              name="title"
              placeholder="Title"
            />
          </div>
          {/* <div class="col-md-12 mb-4">
        <input
          class="form-control"
          type="file"
          id="formFile"
          name="videoUploadLink"
          accept="video/mp4,video/x-m4v,video/*"
          defaultValue={formValues.file_url}
        />
      </div> */}
          <div class="col-md-6 mb-4">
            <select
              name="duration"
              // className="form-select"
            >
              <option hidden>Select Duration</option>
              {[...Array(numberOfOptions).keys()].map((index) => (
                <option
                  key={index + 1}
                  value={String(index + 1).padStart(2, "0")}
                >
                  {`${String(index + 1).padStart(2, "0")} Mins`}
                </option>
              ))}
            </select>
          </div>

          <div class="col-md-6 mb-4">
            <select
              name="genre"
              // className="form-select"
            >
              <option hidden>Select Genre</option>
              <option value="action">Action</option>
              <option value="adventure">Adventure</option>
              <option value="comedy">Comedy</option>
              <option value="drama">Drama</option>
              <option value="fantasy">Fantasy</option>
              <option value="horror">Horror</option>
              <option value="musicals">Musicals</option>
              <option value="mystery">Mystery</option>
              <option value="romance">Romance</option>
              <option value="science-fiction">Science Fiction</option>
              <option value="sports">Sports</option>
              <option value="thriller">Thriller</option>
              <option value="Western">Western</option>
            </select>
          </div>
          <div class="col-md-12 mb-">
            <textarea
              name="video_synopsis"
              class="mb-4"
              rows="2"
              placeholder="Brief Short Film Synopsis"
            ></textarea>
            <br />
            <textarea
              name="cast"
              class="mb-4"
              rows="2"
              placeholder="Cast"
            ></textarea>
            {/* <br /> */}
          </div>
          <div className="row mt-">
            <h5 className="">[Upload Cast and Crew Images]</h5>
            <span>[Accepted file formats: PNG, JPEG ] [Max. Size: 50MB]</span>
            <div className="text-end mb-3">
              <div className=" position-relative cursor-pointer">
                {/* + Add{" "} */}
                <input
                  type="file"
                  className="form-control cursor-pointer"
                  accept="image/png, image/jpeg"
                  onChange={(e) => handleAddImages(e)}
                />
              </div>
            </div>

            <div className="row mx-0 mb-4">
              {imagesArray.length > 0 &&
                imagesArray.map((list, index) => {
                  return (
                    <>
                      <div className="position-relative col-lg-2 col-md-2">
                        <span
                          className="mb- borde shadow cursor-pointer"
                          key={index}
                        >
                          {" "}
                          <div className="mb-3 editicon bg-gray-400 position-relative symbol symbol-175px bg-white pt- w-100">
                            {list && list["type"].split("/")[0] === "image" ? (
                              <>
                                <span
                                  className="close-btn"
                                  onClick={() => {
                                    HandleDeleteLayoutImage(index);
                                  }}
                                >
                                  <i className="fa fa-times text-dark"></i>
                                </span>
                                <a
                                  href={URL.createObjectURL(list)}
                                  target="_blank"
                                >
                                  <img
                                    src={URL.createObjectURL(list)}
                                    className="w-100"
                                  />
                                </a>
                              </>
                            ) : (
                              <video className="h-175px w-100" controls>
                                <source
                                  src={URL.createObjectURL(list)}
                                  type={list.type}
                                />
                              </video>
                            )}
                          </div>
                        </span>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>

          <div class="col-md-12 mb-4">
            <textarea
              name="crew"
              class="mb-4"
              rows="2"
              placeholder="Crew"
            ></textarea>
            <br />

            <textarea
              name="equipment_used"
              class="mb-4"
              rows="2"
              placeholder="Equipment used"
            ></textarea>
            <br />
            {/* <input type="submit" value="Upload" class="mt-4" /> */}
            {/* 
            <button className="btn btn-danger" type="submit">
              UPLOAD
            </button> */}
            <div className="text-right">
              {/* <button
                className="btn btn-info"
                //   onClick={handleSubmit}
                type="submit"
              >
                Next
              </button> */}
              <button
                className="btn btn-info"
                onClick={handleShowAlert} // Show the alert popup
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal show={showAlert} onHide={handleCloseAlert} className="">
        <Modal.Header closeButton className="bg-dark border-0 text-white">
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          1. Upon completion of the competition, participants may request the
          removal of their short film. The film will be removed from our channel
          within one month of the request.
          <br />
          2. Participants do not have the right to any revenue generated while
          their short film is featured on our channel.
          <br />
          3. Short films submitted to TAS should exclusively remain on our
          channel, unless otherwise requested for removal by the participant.
          <br />
          4. Participants are responsible for obtaining copyright clearance for
          their submissions. Failure to do so may result in disqualification
          from the competition.
          <br />
          5. The company reserves the right to include advertisements or
          sponsorships in submitted short films.
          <br />
          6. All participants must display the TAS logo in the top right corner
          of their submissions. Failure to do so will result in the company
          adding it on behalf of the participant.
        </Modal.Body>
        <Modal.Footer className="bg-dark border-0">
          <Button
            variant="link"
            className="text-light"
            onClick={handleCloseAlert}
          >
            Cancel
          </Button>
          <Button className="gen-button" onClick={handleSubmit}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
