import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Step, Stepper } from "react-form-stepper";
import { VideoDetails } from "./videoDetails";
import { PaymentDetails } from "./paymentDetails";
import { VideoUpload } from "./videoUpload";

export const VideoUploadPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // returns current object

  const [progressModal, setProgressModal] = useState(false);

  const [videoId, setVideoId] = useState("");

  // initialValues
  const initialStepper =
    Cookies.get("counter") !== undefined ? Cookies.get("counter") : 0;

  const [activeStepNumber, setActiveStepNumber] = useState(initialStepper);

  const handleNext = () => {
    // e.preventDefault(); // 👈️ prevent page refresh
    if (activeStepNumber < 2) {
      console.log("called");
      setActiveStepNumber(parseInt(activeStepNumber) + 1);
      Cookies.set("counter", parseInt(activeStepNumber) + 1);
    }
  };

  return (
    <>
      <div class="pt-5"></div>
      <section class="gen-section-padding-3 mt-5">
        <div class="container">
          {/* <div class="row"> */}
          {/* <div class="col-lg-12   justify-content-center"> */}
          {/* <div class="gen-episode-wrapper style-1"> */}
          {/* <div class="justify-content-center"> */}
          <div class="col-lg-8 mx-auto">
            <Stepper activeStep={activeStepNumber}>
              <Step label="Video Details" />
              <Step label="Payment" />
              <Step label="Upload" />
            </Stepper>
            <div class="gen-video-holder">
              {activeStepNumber == 0 && (
                <VideoDetails
                  activeStepNumber={activeStepNumber}
                  handleNext={handleNext}
                  setVideoId={setVideoId}
                />
              )}
              {activeStepNumber == 1 && (
                <PaymentDetails
                  activeStepNumber={activeStepNumber}
                  handleNext={handleNext}
                  videoId={videoId}
                />
              )}
              {activeStepNumber == 2 && (
                <VideoUpload
                  activeStepNumber={activeStepNumber}
                  progressModal={progressModal}
                  setProgressModal={setProgressModal}
                  videoId={videoId}
                />
              )}
            </div>
          </div>
          {/* <div class="col-lg-6">
                    <p class="font-weight-bold fs-2 pb-0 mb-2">
                      The following are the rules of the TAS short film festival
                    </p>

                    <ul class="list-unstyled fs-6">
                      <li>
                        1. Submitted short film must be shot in the year 2023.{" "}
                      </li>
                      <li>
                        2. Duration of the short film should be a minimum of 10
                        mins and should not exceed 15 mins including credits.{" "}
                      </li>
                      <li>
                        3. Entrants should submit the details of their college
                        and ID numbers.{" "}
                      </li>
                      <li>
                        4. Recent graduates can also take part in the festival.
                        Pass outs of 2021,2022 and 2023.{" "}
                      </li>
                      <li>
                        5. Entrants can use DSLR and IPhone 14 pro or 15 pro for
                        shoot. Anything other than these will be disqualified
                        directly.{" "}
                      </li>
                      <li>
                        6. The entrants should not use any sort of copy righted
                        content including the music. Using copyrighted content
                        will directly disqualify the entrants.{" "}
                      </li>
                      <li>
                        7. Entrants can either get the music done by themselves
                        or they can use the YouTube library music (no copyright
                        music) or any other no copyrighted music.
                      </li>
                      <li>
                        8. Entrants must give full details of cast and crew. If
                        film experts are found in cast or crew the entrants will
                        be disqualified.
                      </li>
                    </ul>

                    <div class="bg-info-dark p-5">
                      <p class="mb-2">
                        Best short film will be awarded for those who has the
                        highest views and audience enagagement. Every short film
                        which passed the jury will be uploaded in the channel.
                        Everyone will have fair chance to win the competition.
                        Best short film will get prize money of 150000 along
                        with money they will also get a chance to travel to one
                        of the metropolitan cities in a private jet (4 members
                        from cast and crew).
                      </p>
                      <p class="mb-2">The following are the awards by Jury.</p>

                      <ul class="list-unstyled">
                        <li>1. Best actor (male) - 10000</li>
                        <li>2. Best actor (female) - 10000</li>
                        <li>3. Best supporting actor (male) - 10000</li>
                        <li>4. Best supporting actor (female) - 10000</li>
                        <li>5. Best Director - 10000</li>
                        <li>6. Best music director - 10000</li>
                        <li>7. Best screenplay writer - 10000</li>
                        <li>8. Best story - 10000</li>
                        <li>9. Best sound design - 10000</li>
                        <li>10. Best Cinematography - 10000</li>
                        <li>11. Best costumes - 10000</li>
                        <li>12. Best editor - 10000</li>
                        <li>13. Best lyrics - 10000</li>
                        <li>14. Best short film Jury - 20000</li>
                      </ul>
                    </div>
                  </div> */}
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </section>
      {/* <Modal
        // {...props}
        show={progressModal} onHide={() => setProgressModal(false)}
        // style={{ backgroundColor: '#3F3E40' }}
        className="bg-setcolor"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Uploading...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Percentage:{`${progress.toFixed(2)}%`}</h4>
          {/* {progress} */}
      {/* <ProgressBar
            striped
            variant="success"
            now={progress}
            label={`Percentage: ${progress.toFixed(2)}%`}
          />
          ;
        </Modal.Body> 
        <Modal.Footer>
          <Button onClick={() => setProgressModal(false)}>Close</Button>
        </Modal.Footer>
                        </Modal>{" "} */}
    </>
  );
};
