import React from "react";

export const Footer = () => {
  return (
    <footer id="gen-footer">
      <div class="gen-footer-style-1">
        <div class="gen-footer-top">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-md-12">
                <div class="widget">
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <img
                        src="assets/images/logo-1.png"
                        class="gen-footer-logo"
                        alt="gen-footer-logo"
                      />
                      <p>
                        Small stories, big impact. Join our short film contest
                        and share your unique perspective with the world.
                      </p>
                      <ul class="social-link d-inline-block">
                        <li>
                          <a
                            href="https://m.facebook.com/theaviatorstudio"
                            class="facebook"
                            target="_blank"
                          >
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/channel/UCbpygUsWnQYnvtx9hPY4Q1w"
                            class="facebook"
                            target="_blank"
                          >
                            <i class="fab fa-youtube"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://x.com/theaviator_std?t=6GHZOy6NM2FUFDNHG_WxUA&s=08"
                            target="_blank"
                            class="facebook"
                          >
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://instagram.com/theaviatorstudio?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr"
                            class="facebook"
                            target="_blank"
                          >
                            <i class="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gen-copyright-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-12 align-self-center">
                <span class="gen-copyright">
                  <a target="_blank" href="#">
                    Copyright 2023 The Aviator Studio All Rights Reserved.
                  </a>{" "}
                  Designed with love{" "}
                  <a
                    href="https://www.codeloop-interactive.com/"
                    target="_blank"
                  >
                    CodeLoop Interactive
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
