import moment from "moment";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotice } from "../../redux/user authentication/actionCreator";

const itemsPerPage = 10;

const QueriesView = ({ tableData }) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const [selectedNotice, setSelectedNotice] = useState(null);
  const [showNotice, setShowNotice] = useState(false);

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // const totalPages = Math.ceil(tableData.length / itemsPerPage);

  // const pageNumbers = [];
  // for (let i = 1; i <= totalPages; i++) {
  //   pageNumbers.push(i);
  // }

  // const handleSort = (column) => {
  //   console.log("called");
  //   if (column === sortColumn) {
  //     setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortColumn(column);
  //     setSortDirection("asc");
  //   }
  // };

  // const sortedData = [...currentItems].sort((a, b) => {
  //   const aValue = sortDirection === "asc" ? a[sortColumn] : b[sortColumn];
  //   const bValue = sortDirection === "asc" ? b[sortColumn] : a[sortColumn];
  //   if (aValue < bValue) return -1;
  //   if (aValue > bValue) return 1;
  //   return 0;
  // });

  const { isAdmin } = useSelector((states) => {
    return {
      isAdmin: states.auth.isAdmin === undefined ? false : states.auth.isAdmin,
    };
  });

  const handleNoticeDelete = (noticeId) => {
    dispatch(deleteNotice(noticeId));
  };

  const handleCloseModal = () => {
    setShowNotice(!showNotice);
  };

  return (
    <>
      <div>
        {tableData.map((notice) => {
          return (
            <ul className="border bg-gray">
              {/* <span> </span> */}
              <li className="d-flex justify-content-between">
                <span>User: {notice.user_name}</span>
                <span>Email: {notice.email}</span>
                <span>Phone: {notice.phone}</span>
                {notice.enquiry_status === "new" ? (
                  <span className="badge badge-success my-auto">
                    {notice.enquiry_status.toUpperCase()}
                  </span>
                ) : (
                  <span className="badge badge-success my-auto">
                    {notice.enquiry_status.toUpperCase()}
                  </span>
                )}
              </li>
              <li>
                <span>Subject: {notice.subject} </span>
                <span className="ms-4 badge badge-success">
                  {moment(notice.created_date).format("DD MMM, yyyy")}
                </span>
                {/* {["true", true].includes(isAdmin) && (
                  <i
                    className="fa fa-trash ms-4"
                    onClick={() => handleNoticeDelete(notice.notice_id)}
                  />
                )} */}
              </li>
              <li>Description : {notice.comments}</li>
            </ul>
          );
        })}
      </div>
    </>
  );
};

export default QueriesView;
