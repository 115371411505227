const actions = {
  UPLOAD_VIDEO_BEGIN: "UPLOAD_VIDEO_BEGIN",
  UPLOAD_VIDEO_SUCCESS: "UPLOAD_VIDEO_SUCCESS",
  UPLOAD_VIDEO_ERR: "UPLOAD_VIDEO_ERR",

  GET_INDIVIDUAL_VIDEO_BEGIN: "GET_INDIVIDUAL_VIDEO_BEGIN",
  GET_INDIVIDUAL_VIDEO_SUCCESS: "GET_INDIVIDUAL_VIDEO_SUCCESS",
  GET_INDIVIDUAL_VIDEO_ERR: "GET_INDIVIDUAL_VIDEO_ERR",
  
  GET_INDIVIDUAL_USER_VIDEOS_BEGIN: "GET_INDIVIDUAL_USER_VIDEOS_BEGIN",
  GET_INDIVIDUAL_USER_VIDEOS_SUCCESS: "GET_INDIVIDUAL_USER_VIDEOS_SUCCESS",
  GET_INDIVIDUAL_USER_VIDEOS_ERR: "GET_INDIVIDUAL_USER_VIDEOS_ERR",

  GET_ALL_VIDEO_BEGIN: "GET_ALL_VIDEO_BEGIN",
  GET_ALL_VIDEO_SUCCESS: "GET_ALL_VIDEO_SUCCESS",
  GET_ALL_VIDEO_ERR: "GET_ALL_VIDEO_ERR",

  UPDATE_ALL_VIDEO_SUCCESS:"UPDATE_ALL_VIDEO_SUCCESS",

  uploadVideoBegin: () => {
    return {
      type: actions.UPLOAD_VIDEO_BEGIN,
    };
  },

  uploadVideoSuccess: (data) => {
    return {
      type: actions.UPLOAD_VIDEO_SUCCESS,
      data,
    };
  },

  uploadVideoErr: (err) => {
    return {
      type: actions.UPLOAD_VIDEO_ERR,
      err,
    };
  },
  getIndividualVideoBegin: () => {
    return {
      type: actions.GET_INDIVIDUAL_VIDEO_BEGIN,
    };
  },

  getIndividualVideoSuccess: (data) => {
    return {
      type: actions.GET_INDIVIDUAL_VIDEO_SUCCESS,
      data,
    };
  },

  getIndividualVideoErr: (err) => {
    return {
      type: actions.GET_INDIVIDUAL_VIDEO_ERR,
      err,
    };
  },
  getIndividualUserVideosBegin: () => {
    return {
      type: actions.GET_INDIVIDUAL_USER_VIDEOS_BEGIN,
    };
  },

  getIndividualUserVideosSuccess: (data) => {
    return {
      type: actions.GET_INDIVIDUAL_USER_VIDEOS_SUCCESS,
      data,
    };
  },

  getIndividualUserVideosErr: (err) => {
    return {
      type: actions.GET_INDIVIDUAL_USER_VIDEOS_ERR,
      err,
    };
  },
  getAllVideoBegin: () => {
    return {
      type: actions.GET_ALL_VIDEO_BEGIN,
    };
  },

  getAllVideoSuccess: (data) => {
    return {
      type: actions.GET_ALL_VIDEO_SUCCESS,
      data,
    };
  },

  getAllVideoErr: (err) => {
    return {
      type: actions.GET_ALL_VIDEO_ERR,
      err,
    };
  },
  UpdateAllVideoSuccess: (err) => {
    return {
      type: actions.UPDATE_ALL_VIDEO_SUCCESS,
      err,
    };
  },
};

export default actions;
