import React, { useEffect, useState } from "react";
import {
  getIndividualUserVideo,
  getIndividualVideo,
  uploadIndividualVideo,
} from "../../redux/videos/actionCreator";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { errorToast, warningToast } from "../../utils";
import { DocumentUploadToS3 } from "../../utils/awsServices";
import { VideoUpload } from "../videoUploadPage/videoUpload";
import { Button, ProgressBar } from "react-bootstrap";

export const VideosPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [videoUploadModal, setVideoUploadModal] = useState(false);
  const [progressModal, setProgressModal] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const registrationId = Cookies.get("registration_id");
    dispatch(getIndividualUserVideo(registrationId));
  }, []);

  const { individualUserVideosData } = useSelector((states) => {
    return {
      individualUserVideosData: states.videos.individualUserVideosData,
    };
  });

  const handleNavigate = () => {
    navigate("/upload-video");
    Cookies.set("counter", 0);
  };

  const handleUploadVideo = async (video) => {
    const fileInput = document.getElementById("formFile");
    const file = fileInput.files[0]; // Get the first selected file
    let isSubmit = true;

    let videoObject = {};
    if (file) {
      // You can proceed to upload the file to your server or perform other actions.
      // For example, you can pass the 'file' object to your 'DocumentUploadToS3' function.
      if (file != "") {
        const videoLink = await DocumentUploadToS3(
          file,
          "short-films",
          setProgress,
          setProgressModal
        );
        videoObject.file_url = videoLink.Location;
      }
    } else {
      // No file selected
      errorToast("Select a File");
      isSubmit = false;
    }
    if (isSubmit) {
      handlePostURL(video, videoObject);
    } else {
      warningToast(
        `Please fill ${isSubmit.charAt(0).toUpperCase() + isSubmit.slice(1)}`
      );
    }
  };

  const handlePostURL = (video, videoObject) => {
    dispatch(
      uploadIndividualVideo(video.video_id, videoObject, (response) => {
        if (response.status === 200) setProgressModal(false);
        // navigate("/my-videos");
        window.location.reload();
      })
    );
  };

  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFileName(file ? file.name : "");
    // Add your other logic here, if needed
  };

  const handleEdit = (videoObj) => {
    navigate(`/edit-video`, { state: { videoDetails: videoObj } });
  };

  return (
    <>
      <div className="pt-5"></div>
      {progressModal && (
        <div className="progress-popup">
          <div className="w-25 text-center">
            <h1>{`${progress.toFixed(2)}%`}</h1>
            <ProgressBar animated now={progress} className="my-3" />
            <p>Uploading Video...</p>
            <div>
              <button className="btn btn-link text-danger">Cancel</button>
            </div>
          </div>
        </div>
      )}
      <section className="gen-section-padding-3 mt-5" id="top">
        <div className="container">
          {Object.keys(individualUserVideosData).length === 0 && (
            <button
              className="btn btn-danger upload-btn py-2 mb-3"
              onClick={handleNavigate}
            >
              <div>
                <i className="fa fa-upload"></i>
              </div>
              UPLOAD
            </button>
          )}
          <div className="row justify-content-center">
            <div className="col-lg-6">
              {/* <button class="btn btn-info btn-block" type="">Upload Video</button> */}
              {Object.keys(individualUserVideosData).length > 0 ? (
                // individualUserVideosData.map((video, index) => {
                //   return (
                <div className="gen-episode-wrapper style-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="gen-video-holder">
                        {individualUserVideosData.youtube_url ? (
                          <iframe
                            width="560"
                            height="315"
                            src={individualUserVideosData.youtube_url}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        ) : (
                          <ReactPlayer
                            controls
                            width="100%"
                            height="100%"
                            url={individualUserVideosData.videoLink}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-episode">
                        <div className="gen-single-tv-show-info">
                          <div className="d-flex">
                            <h2 className="gen-title">
                              {individualUserVideosData.title}
                            </h2>{" "}
                            <i
                              className="fa fa-edit
                                  text-danger ms-4 my-auto"
                              onClick={() =>
                                handleEdit(individualUserVideosData)
                              }
                            >
                              {/* Edit */}
                            </i>
                          </div>
                          {individualUserVideosData.file_url === "" &&
                            individualUserVideosData.payment_status ===
                              "approved" && (
                              <>
                                <div className="upload-vid my-3">
                                  <input
                                    type="file"
                                    className="upload-input"
                                    title="Upload Video"
                                    id="formFile"
                                    name="videoUploadLink"
                                    accept="video/mp4,video/x-m4v,video/*,video/x-matroska,video/webm"
                                    onChange={handleFileChange}
                                  />
                                  <span>
                                    <i className="fa fa-upload"></i> Upload
                                    Video
                                  </span>
                                  <span>{selectedFileName}</span>
                                </div>
                                <div className="text-right">
                                  <button
                                    className="btn btn-danger mt-2"
                                    onClick={() =>
                                      handleUploadVideo(
                                        individualUserVideosData
                                      )
                                    }
                                    type="submit"
                                  >
                                    UPLOAD
                                  </button>
                                </div>
                              </>
                            )}{" "}
                          <p>{individualUserVideosData.video_synopsis}</p>
                          <div className="gen-after-excerpt">
                            <div className="gen-extra-data">
                              <ul>
                                {/* <li>
                                <span>Language :</span>
                                <span>English</span>
                              </li>
                              <li>
                                <span>Subtitles :</span>
                                <span>English</span>
                              </li>
                              <li>
                                <span>Audio Languages :</span>
                                <span>English</span>
                              </li> */}
                                <li>
                                  <span>Genre :</span>
                                  <span>
                                    <a href="action.html">
                                      {individualUserVideosData.genre}{" "}
                                    </a>
                                  </span>
                                  {/* <span>
                                  <a href="adventure.html">Documentary</a>
                                </span> */}
                                </li>
                                <li>
                                  <span>Run Time :</span>
                                  <span>
                                    {individualUserVideosData.duration}
                                  </span>
                                </li>
                                <li>
                                  <span>Cast :</span>
                                  <span>{individualUserVideosData.cast}</span>
                                </li>
                                <li>
                                  <span>Crew :</span>
                                  <span>{individualUserVideosData.crew}</span>
                                </li>
                                <li>
                                  <span>Status :</span>
                                  {individualUserVideosData.status ===
                                  "active" ? (
                                    <span className="badge badge-success">
                                      {individualUserVideosData.status.toUpperCase()}
                                    </span>
                                  ) : individualUserVideosData.status ===
                                    "in-progress" ? (
                                    <span className="badge badge-warning text-dark">
                                      {individualUserVideosData.status.toUpperCase()}{" "}
                                    </span>
                                  ) : individualUserVideosData.status ===
                                    "rejected" ? (
                                    <span className="badge badge-danger text-dark">
                                      {individualUserVideosData.status.toUpperCase()}{" "}
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      {individualUserVideosData.status.toUpperCase()}{" "}
                                    </span>
                                  )}
                                </li>
                                <li>
                                  <span>Payment status :</span>
                                  {individualUserVideosData.payment_status ===
                                  "approved" ? (
                                    <span className="badge badge-success">
                                      {individualUserVideosData.payment_status.toUpperCase()}
                                    </span>
                                  ) : individualUserVideosData.payment_status ===
                                    "paid" ? (
                                    <span className="badge badge-success">
                                      {individualUserVideosData.payment_status.toUpperCase()}
                                    </span>
                                  ) : individualUserVideosData.payment_status ===
                                    "not-paid" ? (
                                    <span className="badge badge-warning text-dark">
                                      {individualUserVideosData.payment_status.toUpperCase()}{" "}
                                    </span>
                                  ) : individualUserVideosData.payment_status ===
                                    "rejected" ? (
                                    <span className="badge badge-danger text-dark">
                                      {individualUserVideosData.payment_status.toUpperCase()}{" "}
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      {individualUserVideosData.payment_status.toUpperCase()}{" "}
                                    </span>
                                  )}
                                </li>
                                <li>
                                  <span>Release Date :</span>
                                  <span>
                                    {moment(
                                      individualUserVideosData.created_date
                                    ).format("DD MMM, yyyy")}
                                  </span>
                                </li>
                              </ul>
                            </div>

                            {/* <div className="gen-socail-share">
                                  <h4 className="align-self-center">
                                    Social Share :
                                  </h4>
                                  <ul className="social-inner">
                                    <li>
                                      <a href="#" className="facebook">
                                        <i className="fab fa-facebook-f"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" className="facebook">
                                        <i className="fab fa-instagram"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" className="facebook">
                                        <i className="fab fa-twitter"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                //   );
                // })
                <>
                  <div className="text-center text-bold">No data Found</div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <div id="back-to-top">
        <a className="top" id="top" href="#top">
          {" "}
          <i className="ion-ios-arrow-up"></i>
        </a>
      </div>
    </>
  );
};
