import React, { useEffect } from "react";
import { getIndividualVideo } from "../../redux/videos/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";

export const IndividualVideoPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { video_id } = params;

  useEffect(() => {
    dispatch(getIndividualVideo(video_id));
  }, []);

  const { individualVideoData } = useSelector((states) => {
    return {
      individualVideoData: states.videos.individualVideoData,
    };
  });

  const handleNavigate = () => {
    navigate("/upload-video");
  };

  return (
    <>
      <div className="pt-5"></div>
      <section className="gen-section-padding-3 mt-5">
        <div className="container">
          <button
            className="btn btn-danger upload-btn py-2 mb-3"
            onClick={handleNavigate}
          >
            <div>
              <i className="fa fa-upload"></i>
            </div>
            UPLOAD
          </button>

          <div className="row justify-content-center">
            <div className="col-lg-6">
              {Object.keys(individualVideoData).length > 0 ? (
                <div className="gen-episode-wrapper style-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="gen-video-holder">
                        {individualVideoData.youtube_url ? (
                          <iframe
                            width="560"
                            height="315"
                            src={individualVideoData.youtube_url}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        ) : (
                          <ReactPlayer
                            controls
                            width="100%"
                            height="100%"
                            url={individualVideoData.videoLink}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-episode">
                        <div className="gen-single-tv-show-info">
                          <h2 className="gen-title">
                            {individualVideoData.title}
                          </h2>

                          <p>{individualVideoData.video_synopsis}</p>
                          <div className="gen-after-excerpt">
                            <div className="gen-extra-data">
                              <ul>
                                {/* <li>
                                <span>Language :</span>
                                <span>English</span>
                              </li>
                              <li>
                                <span>Subtitles :</span>
                                <span>English</span>
                              </li>
                              <li>
                                <span>Audio Languages :</span>
                                <span>English</span>
                              </li> */}
                                <li>
                                  <span>Genre :</span>
                                  <span>
                                    <a href="action.html">
                                      {individualVideoData.genre}{" "}
                                    </a>
                                  </span>
                                  {/* <span>
                                  <a href="adventure.html">Documentary</a>
                                </span> */}
                                </li>
                                <li>
                                  <span>Run Time :</span>
                                  <span>{individualVideoData.duration}</span>
                                </li>
                                <li>
                                  <span>Cast :</span>
                                  <span>{individualVideoData.cast}</span>
                                </li>
                                <li>
                                  <span>Crew :</span>
                                  <span>{individualVideoData.crew}</span>
                                </li>
                                <li>
                                  <span>Release Date :</span>
                                  <span>
                                    {moment(
                                      individualVideoData.created_date
                                    ).format("DD MMM, yyyy")}
                                  </span>
                                </li>
                                <li>
                                  <span>Status :</span>
                                  {individualVideoData.status === "active" ? (
                                    <span className="badge badge-success">
                                      {individualVideoData.status.toUpperCase()}
                                    </span>
                                  ) : individualVideoData.status ===
                                    "in-progress" ? (
                                    <span className="badge badge-warning">
                                      {individualVideoData.status.toUpperCase()}{" "}
                                    </span>
                                  ) : (
                                    <span className="badge badge-warning">
                                      {individualVideoData.status.toUpperCase()}{" "}
                                    </span>
                                  )}
                                </li>
                                <li>
                                  <span>Uploaded by :</span>
                                  <span>{`${individualVideoData.first_name} ${individualVideoData.last_name} (${individualVideoData.registration_id})`}</span>
                                </li>
                              </ul>
                            </div>
                            {/* <div className="gen-socail-share">
                              <h4 className="align-self-center">
                                Social Share :
                              </h4>
                              <ul className="social-inner">
                                <li>
                                  <a href="#" className="facebook">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="facebook">
                                    <i className="fab fa-instagram"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="facebook">
                                    <i className="fab fa-twitter"></i>
                                  </a>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-center text-bold">No data Found</div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
