import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducers';

const store = createStore(rootReducer, compose(applyMiddleware(thunk.withExtraArgument())));

// export const rrfProps = {
//   firebase,
//   config: (fbConfig, { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true }),
//   dispatch: store.dispatch,
//   createFirestoreInstance,
// };

export default store;
