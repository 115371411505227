import moment from "moment";
import React, { useState } from "react";

const itemsPerPage = 10;

const UsersTable = ({ tableData, handleDeleteUser }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [showPasswordForUserId, setShowPasswordForUserId] = useState(null);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = [...currentItems].sort((a, b) => {
    const aValue = sortDirection === "asc" ? a[sortColumn] : b[sortColumn];
    const bValue = sortDirection === "asc" ? b[sortColumn] : a[sortColumn];
    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;
    return 0;
  });

  const handleShowPassword = (userId) => {
    setShowPasswordForUserId(userId === showPasswordForUserId ? null : userId);
  };

  return (
    <div>
      <table className="table table-dark table-hover">
        <thead>
          <tr>
            <th>
              <span onClick={() => handleSort("video_id")}>
                REGISTRATION ID
              </span>
            </th>
            <th onClick={() => handleSort("title")}>NAME</th>
            <th onClick={() => handleSort("duration")}>JOINING DATE</th>
            <th>PHONE</th>
            <th>EMAIL</th>
            <th>PASSWORD</th>
            <th>STATUS</th>
            <th>VIDEOS COUNT</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <tr key={item.registration_id}>
              <td>{item.registration_id}</td>
              <td className="text-left">{`${item.first_name} ${item.last_name}`}</td>
              <td>{moment(item.created_date).format("DD MMM, yyyy")}</td>
              <td> {item.phone}</td>
              <td>
                {/* <span className="badge badge-info"> */}
                {item.email}
                {/* </span> */}
              </td>
              <td>
                {showPasswordForUserId === item.registration_id ? (
                  <span>{item.code ? item.code : ""}</span>
                ) : (
                  <span>{"*".repeat(item.code ? item.code.length : "")}</span>
                )}{" "}
                {item.code && (
                  <i
                    className={`fa ${
                      showPasswordForUserId === item.registration_id
                        ? "fa-eye-slash"
                        : "fa-eye"
                    }`}
                    onClick={() => handleShowPassword(item.registration_id)}
                  />
                )}
              </td>

              <td>
                {item.status === "active" ? (
                  <span className="badge badge-success">
                    {item.status.toUpperCase()}
                  </span>
                ) : item.status === "in-progress" ? (
                  <span className="badge badge-warning">
                    {item.status.toUpperCase()}{" "}
                  </span>
                ) : (
                  <span className="badge badge-warning">
                    {item.status.toUpperCase()}{" "}
                  </span>
                )}
              </td>
              <td>
                {item.videos.map((list) => {
                  return list?.status === "active" ? (
                    <span className="badge badge-success fs-6 me-2">{`Active: ${list.count}`}</span>
                  ) : list?.status === "in-progress" ? (
                    <span className="badge badge-warning fs-6 me-2">{`Pending: ${list.count}`}</span>
                  ) : list?.status === "rejected" ? (
                    <span className="badge badge-danger fs-6 me-2">{`Rejected: ${list.count}`}</span>
                  ) : (
                    ""
                  );
                })}
              </td>

              <td>
                {item.status !== "deleted" && (
                  <i
                    className="fa fa-trash"
                    onClick={() =>
                      handleDeleteUser(item.registration_id, "deleted")
                    }
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsersTable;
