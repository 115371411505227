import React, { useEffect } from "react";
// import UsersTable from "../../components/bootstrapTable/usersTable";
import {
  getAllUsers,
  updateUserStatus,
} from "../../redux/user authentication/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import UsersTable from "../../components/bootstrapTable/UsersTable";

export const Users = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const { allUsers } = useSelector((state) => {
    return {
      allUsers: state.auth.allUsers.filter((user) => user.type !== "admin"),
    };
  });

  const handleDeleteUser = (registration_id, status) => {
    const data = { status: status };
    dispatch(updateUserStatus(registration_id, data));
  };
  return (
    <>
      <div className="pt-5"></div>
      <section className="gen-section-padding-3 mt-5" id="top">
        <div className="container">
          <div className="row justify-content-center">
            <UsersTable
              tableData={allUsers}
              handleDeleteUser={handleDeleteUser}
            />
          </div>
        </div>
      </section>
    </>
  );
};
