import Cookies from "js-cookie";
import actions from "./actions";

const {
  UPLOAD_VIDEO_BEGIN,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_ERR,
  GET_INDIVIDUAL_VIDEO_BEGIN,
  GET_INDIVIDUAL_VIDEO_SUCCESS,
  GET_INDIVIDUAL_VIDEO_ERR,
  GET_INDIVIDUAL_USER_VIDEOS_BEGIN,
  GET_INDIVIDUAL_USER_VIDEOS_SUCCESS,
  GET_INDIVIDUAL_USER_VIDEOS_ERR,
  GET_ALL_VIDEO_BEGIN,
  GET_ALL_VIDEO_SUCCESS,
  GET_ALL_VIDEO_ERR,
  UPDATE_ALL_VIDEO_SUCCESS,
} = actions;

const initState = {
  individualVideoData: {},
  individualUserVideosData: {},
  allVideos: [],
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const allVideosReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPLOAD_VIDEO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        uploadedVideoData: data,
        loading: false,
      };
    case UPLOAD_VIDEO_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_INDIVIDUAL_VIDEO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_INDIVIDUAL_VIDEO_SUCCESS:
      return {
        ...state,
        individualVideoData: data,
        loading: false,
      };
    case GET_INDIVIDUAL_VIDEO_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_INDIVIDUAL_USER_VIDEOS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_INDIVIDUAL_USER_VIDEOS_SUCCESS:
      console.log("vvvvv", data);
      return {
        ...state,
        individualUserVideosData: data,
        loading: false,
      };
    case GET_INDIVIDUAL_USER_VIDEOS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_ALL_VIDEO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_VIDEO_SUCCESS:
      return {
        ...state,
        allVideos: data,
        loading: false,
      };
    case GET_ALL_VIDEO_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_ALL_VIDEO_SUCCESS:
      return {
        ...state,
        allVideos: data,
        loading: false,
      };
    default:
      return state;
  }
};
export default allVideosReducer;
