import moment from "moment";
import React, { useState } from "react";

const itemsPerPage = 10;

const TableWithPagination = ({
  tableData,
  handleVideoStatusUpdate,
  handlePaymentStatusUpdate,
  handleView,
  handleViewPayment,
  handleDownload,
  handleEdit,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = [...currentItems].sort((a, b) => {
    const aValue = sortDirection === "asc" ? a[sortColumn] : b[sortColumn];
    const bValue = sortDirection === "asc" ? b[sortColumn] : a[sortColumn];
    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;
    return 0;
  });

  return (
    <div>
      <table className="table table-dark table-hover">
        <thead>
          <tr>
            <th>
              <span onClick={() => handleSort("video_id")}>VIDEO ID</span>
            </th>
            <th onClick={() => handleSort("title")}>NAME</th>
            <th onClick={() => handleSort("duration")}>DURATION</th>
            <th onClick={() => handleSort("created_date")}>UPLOADED DATE</th>
            <th onClick={() => handleSort("first_name")}>UPLOADED BY</th>
            <th onClick={() => handleSort("genre")}>GENRE</th>
            <th>STATUS</th>
            <th>VIEW</th>
            <th>PAYMENT PROOF</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <tr key={item.video_id}>
              <td>{item.video_id}</td>
              <td className="text-left">{item.title}</td>
              <td>{`${item.duration} Mins`}</td>
              <td>{moment(item.created_date).format("DD MMM, yyyy")}</td>
              <td>{`${item.first_name} ${item.last_name} (${item.registration_id})`}</td>
              <td>
                <span className="badge badge-info">
                  {item.genre.toUpperCase()}
                </span>
              </td>
              <td>
                {item.status === "active" ? (
                  <span className="badge badge-success">
                    {item.status.toUpperCase()}
                  </span>
                ) : item.status === "in-progress" ? (
                  <span className="badge badge-warning">
                    {item.status.toUpperCase()}{" "}
                  </span>
                ) : (
                  <span className="badge badge-warning">
                    {item.status.toUpperCase()}{" "}
                  </span>
                )}
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <span
                    className="btn btn-primary me-1"
                    onClick={() => handleView(item)}
                  >
                    view
                  </span>
                  <span
                    className="btn btn-info me-1"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </span>
                  {item.videoLink && (
                    <span
                      className="btn btn-secondary"
                      onClick={() => handleDownload(item)}
                    >
                      download
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  {["paid", "approved"].includes(item.payment_status) && (
                    <span
                      className="btn btn-primary me-1"
                      onClick={() => handleViewPayment(item)}
                    >
                      view
                    </span>
                  )}
                  {item.payment_status === "paid" && (
                    <>
                      <div
                        className="btn btn-success me-1"
                        onClick={() =>
                          handlePaymentStatusUpdate(item.registration_id, "approved")
                        }
                      >
                        Approve
                      </div>
                      <span
                        className="btn btn-warning me-1"
                        onClick={() =>
                          handlePaymentStatusUpdate(item.registration_id, "rejected")
                        }
                      >
                        Reject
                      </span>
                    </>
                  )}
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  {item.payment_status === "approved" && item.status ==='in-progress' ? (
                    <>
                      <div
                        className="btn btn-success me-1"
                        onClick={() =>
                          handleVideoStatusUpdate(item.video_id, "active")
                        }
                        // disabled={item.videoLink ? "" : "disabled"}
                      >
                        Approve
                      </div>
                      <span
                        className="btn btn-warning me-1"
                        onClick={() =>
                          handleVideoStatusUpdate(item.video_id, "rejected")
                        }
                      >
                        Reject
                      </span>
                    </>
                  ) : item.status === "active" ? (
                    <span
                      className="btn btn-warning me-1"
                      onClick={() =>
                        handleVideoStatusUpdate(item.video_id, "rejected")
                      }
                    >
                      Reject
                    </span>
                  ) : (
                    ""
                    // <div
                    //   className="btn btn-success me-1"
                    //   onClick={() =>
                    //     handleVideoStatusUpdate(item.video_id, "active")
                    //   }
                    // >
                    //   Approve
                    // </div>
                  )}

                  {item.status !== "deleted" && (
                    <span
                      className="btn btn-danger me-1"
                      onClick={() =>
                        handleVideoStatusUpdate(item.video_id, "deleted")
                      }
                    >
                      Delete
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableWithPagination;
