import AWS from "aws-sdk";
import { errorToast } from ".";
import Cookies from "js-cookie";
import { useState } from "react";

const {
  REACT_APP_BUCKET_NAME,
  REACT_APP_ACCESS_KEY,
  REACT_APP_SECRET_KEY,
  REACT_APP_REGION,
  REACT_APP_EXPIRES,
} = process.env;

const abortController = new AbortController();

export const DocumentUploadToS3 = async (
  file,
  role,
  setProgress,
  setProgressModal
) => {
  return new Promise(async (resolve, reject) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789BLOOMING";
    let genFileKeytem = "";

    for (let i = 0; i < 20; i++) {
      genFileKeytem += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    let keyName;

    if (role === "short-films") {
      keyName = `short-films-${Cookies.get(
        "registration_id"
      )}/videos/${genFileKeytem}.${file.name.split(".").pop()}`;
    } else if (role === "short-films-crew-images") {
      keyName = `short-films-crew-images-${Cookies.get(
        "registration_id"
      )}/images/${genFileKeytem}.${file.name.split(".").pop()}`;
    } else if (role === "short-films-payment-images") {
      keyName = `short-films-payment-images-${Cookies.get(
        "registration_id"
      )}/videos/${genFileKeytem}.${file.name.split(".").pop()}`;
    }

    const params = {
      ACL: "private", // Change "acl" to "ACL"
      Body: file,
      Bucket: REACT_APP_BUCKET_NAME,
      Key: keyName,
      ContentType: file.type,
      Signal: abortController.signal,
    };

    AWS.config.update({
      accessKeyId: REACT_APP_ACCESS_KEY,
      secretAccessKey: REACT_APP_SECRET_KEY,
    });

    const myBucket = new AWS.S3({
      params: { Bucket: REACT_APP_BUCKET_NAME },
      region: REACT_APP_REGION,
    });

    const upload = myBucket.upload(params); // Removed "new" before "myBucket.upload"

    upload.on("httpUploadProgress", (progress) => {
      const percentUploaded = (progress.loaded / progress.total) * 100;
      // if (onProgress) {
      //   onProgress(percentUploaded);
      // }
      setProgress(percentUploaded);
      setProgressModal(true);
    });

    // // Create a method to cancel the upload
    // const cancelUpload = () => {
    //   if (upload) {
    //     upload.abort(); // Call the abort method to cancel the upload
    //   }
    //   reject(new Error("Upload canceled")); // Reject the promise with a cancellation error
    // };

    // // Return an object that contains the cancelUpload function
    // // This allows you to call cancelUpload() to cancel the upload
    // resolve({ cancelUpload });

    upload.send((err, resposeData) => {
      // Changed "send" instead of "upload"
      if (resposeData) {
        resolve(resposeData);
      } else {
        console.log(err, "err");
        reject(err); // Reject the promise in case of an error
      }
    });
  });
};

/**  ********************************************
    Fetch videos details from s3 bucket 
 ********************************************  */
export const generateAWSVideosURL = async (arr) => {
  const signedURLPromises = arr.map((nObj) => {
    return new Promise((resolve) => {
      const { file_url } = nObj;
      const key1 = file_url.split(".com/");
      if (key1[0].includes(REACT_APP_BUCKET_NAME)) {
        const S3 = new AWS.S3();
        AWS.config.update({
          accessKeyId: REACT_APP_ACCESS_KEY,
          secretAccessKey: REACT_APP_SECRET_KEY,
          region: REACT_APP_REGION,
        });
        S3.getSignedUrl(
          "getObject",
          {
            Bucket: REACT_APP_BUCKET_NAME,
            Expires: parseInt(REACT_APP_EXPIRES),
            Key: key1[1],
          },
          (err, video) => {
            if (err) {
              nObj["videoLink"] = null;
            } else {
              nObj["videoLink"] = video;
            }
            resolve(nObj);
          }
        );
      } else {
        nObj["videoLink"] = null;
        resolve(nObj);
      }
    });
  });

  return Promise.all(signedURLPromises).then((arrWithVideoLinks) => {
    return arrWithVideoLinks;
  });
};

/**  ********************************************
    Fetch Images details from s3 bucket 
 ********************************************  */

export const generateAWSImagesURL = async (arr) => {
  AWS.config.update({
    accessKeyId: REACT_APP_ACCESS_KEY,
    secretAccessKey: REACT_APP_SECRET_KEY,
    region: REACT_APP_REGION,
  });
  const signedURLPromises = arr.map((url) => {
    return new Promise(async (resolve) => {
      const keyParts = url.split(".com/");
      if (keyParts[0].includes(REACT_APP_BUCKET_NAME)) {
        try {
          const signedUrl = await new AWS.S3().getSignedUrlPromise(
            "getObject",
            {
              Bucket: REACT_APP_BUCKET_NAME,
              Expires: parseInt(REACT_APP_EXPIRES),
              Key: keyParts[1],
            }
          );

          resolve(signedUrl);
        } catch (err) {
          // Handle error, if needed
          resolve("");
        }
      } else {
        resolve("");
      }
    });
  });

  return Promise.all(signedURLPromises);
};

// export const generateAWSImagesURL = async (arr) => {
//   const S3 = new AWS.S3();
//   AWS.config.update({
//     accessKeyId: REACT_APP_ACCESS_KEY,
//     secretAccessKey: REACT_APP_SECRET_KEY,
//     region: REACT_APP_REGION,
//   });

//   const signedURLPromises = arr.map((url) => {
//     return S3.getSignedUrlPromise("getObject", {
//       Bucket: REACT_APP_BUCKET_NAME,
//       Expires: parseInt(REACT_APP_EXPIRES),
//       Key: url.split(".com/")[1],
//     });
//   });

//   return Promise.all(signedURLPromises);
// };

/**  ********************************************
    Fetch single video from s3 bucket   
 ********************************************  */
export const generateAWSPresignedURL2 = async (obj) => {
  return new Promise((resolve, reject) => {
    let key1 = obj.file_url.split(".com/");
    const S3 = new AWS.S3();
    AWS.config.update({
      accessKeyId: REACT_APP_ACCESS_KEY,
      secretAccessKey: REACT_APP_SECRET_KEY,
      region: REACT_APP_REGION,
    });
    S3.getSignedUrl(
      "getObject",
      {
        Bucket: REACT_APP_BUCKET_NAME,
        Expires: parseInt(REACT_APP_EXPIRES),
        Key: key1[1],
      },
      async (err, video) => {
        if (video == undefined) {
          resolve(obj);
        } else {
          obj["videoLink"] = video;
          resolve(obj);
        }
      }
    );
  });
};

export const deleteVideoFromS3 = async (s3Url) => {
  // Parse the URL to extract bucketName and videoKey
  const urlParts = s3Url.split("/");
  const bucketName = urlParts[3]; // Bucket name is the fourth part of the URL
  const videoKey = urlParts.slice(4).join("/"); // Join the rest of the parts to get the video key

  // Define the parameters for the delete operation.
  const params = {
    Bucket: bucketName, // The name of your S3 bucket.
    Key: videoKey, // The key (path) of the video object to delete.
  };

  try {
    const S3 = new AWS.S3();
    // Use the `deleteObject` method with `promise()` to asynchronously delete the object.
    await S3.deleteObject(params).promise();
    console.log(`Video ${videoKey} deleted successfully.`);
  } catch (error) {
    console.error(`Error deleting video ${videoKey}:`, error);
  }
};

export const generateSingleImagePresignURL = async (URL) => {
  console.log("URL", URL);
  return new Promise(async (resolve, reject) => {
    try {
      let key1 = URL.split(".com/");
      const S3 = new AWS.S3();
      AWS.config.update({
        accessKeyId: REACT_APP_ACCESS_KEY,
        secretAccessKey: REACT_APP_SECRET_KEY,
        region: REACT_APP_REGION,
      });
      const pic = await S3.getSignedUrlPromise("getObject", {
        Bucket: REACT_APP_BUCKET_NAME,
        Expires: parseInt(REACT_APP_EXPIRES),
        Key: key1[1],
      });

      if (pic == undefined) {
        resolve("");
      } else {
        resolve(pic);
      }
    } catch (error) {
      reject(error);
    }
  });
};
