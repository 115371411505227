import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import {
  UpdateAllVideo,
  UpdatePaymentStatus,
  UpdateVideoStatus,
  getAllVideo,
} from "../../redux/videos/actionCreator";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import {
  generateAWSPresignedURL2,
  generateSingleImagePresignURL,
} from "../../utils/awsServices";
import { successToast } from "../../utils";
import TableWithPagination from "../../components/bootstrapTable";

export const VideosControlPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    REACT_APP_BUCKET_NAME,
    REACT_APP_ACCESS_KEY,
    REACT_APP_SECRET_KEY,
    REACT_APP_REGION,
    REACT_APP_EXPIRES,
  } = process.env;

  useEffect(() => {
    const registrationId = Cookies.get("registration_id");
    dispatch(getAllVideo(registrationId));
  }, []);

  const { allVideos } = useSelector((states) => {
    return {
      allVideos: states.videos.allVideos,
    };
  });

  /**  ********************************************
        Fetch videos details from s3 bucket 
     ********************************************  */
  const generateAWSDocumentURL = async (arr) => {
    let temp = [];
    await arr.map(async (nObj, index) => {
      temp.push(
        new Promise((resolve, reject) => {
          const { file_url } = nObj;
          let key1 = file_url.split(".com/");
          if (key1[0].includes(REACT_APP_BUCKET_NAME)) {
            const S3 = new AWS.S3();
            AWS.config.update({
              accessKeyId: REACT_APP_ACCESS_KEY,
              secretAccessKey: REACT_APP_SECRET_KEY,
              region: REACT_APP_REGION,
            });
            S3.getSignedUrl(
              "getObject",
              {
                Bucket: REACT_APP_BUCKET_NAME,
                Expires: parseInt(REACT_APP_EXPIRES),
                Key: key1[1],
              },
              async (err, video) => {
                if (video == undefined) {
                  nObj["videoLink"] = arr.file_url;
                  resolve();
                } else {
                  nObj["videoLink"] = video;
                  resolve();
                }
              }
            );
          } else {
            nObj["videoLink"] = null;
            resolve();
          }
        })
      );
      Promise.all(temp).then((res) => {
        UpdateAllVideo(arr);
      });
    });
  };

  const handlePaymentStatusUpdate = (video_id, payment_status) => {
    const paymentObject = {
      payment_status: payment_status,
    };

    dispatch(
      UpdatePaymentStatus(video_id, paymentObject, (res) => {
        if (res.status === 200) {
          successToast(res.message);
          // dispatch(UpdateVideoStatus(video_id, obj));
        }
      })
    );
  };

  const handleVideoStatusUpdate = (video_id, status) => {
    const obj = {
      status: status,
    };
    dispatch(UpdateVideoStatus(video_id, obj));
  };

  const handleViewPayment = async (videoObj) => {
    const { payment_proof } = videoObj;
    let URL = await generateSingleImagePresignURL(payment_proof);
    if (URL) {
      window.open(URL, "_blank");
    } else {
      console.error("Payment proof URL is empty.");
    }
  };

  const handleView = (videoObj) => {
    const { video_id } = videoObj;
    navigate(`view-videos/${video_id}`);
  };
  const handleEdit = (videoObj) => {
    const { video_id } = videoObj;
    // navigate(`upload-video/${video_id}`, { state: { videoDetails: videoObj } });
    navigate(`/edit-video`, { state: { videoDetails: videoObj } })
  };
  const handleDownload = async (videoObj) => {
    // Create an anchor element (a) to trigger the download
    const anchor = document.createElement("a");

    const URL = await generateAWSPresignedURL2(videoObj);
    // Set the href attribute to the file URL
    anchor.href = URL.videoLink;

    // Set the target attribute to "_blank" to open the link in a new tab
    anchor.target = "_blank";

    // Set the download attribute to suggest the file name (optional)
    anchor.download = `${videoObj.title}.mp4`;

    // Trigger a click event on the anchor element to start the download
    anchor.click();

    // Remove the anchor element from the DOM
    // document.body.removeChild(anchor);
  };

  return (
    <>
      <div className="pt-5"></div>
      <section className="gen-section-padding-3 mt-5" id="top">
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-lg-6"> */}

            <TableWithPagination
              tableData={allVideos}
              handlePaymentStatusUpdate={handlePaymentStatusUpdate}
              handleVideoStatusUpdate={handleVideoStatusUpdate}
              handleView={handleView}
              handleDownload={handleDownload}
              handleEdit={handleEdit}
              handleViewPayment={handleViewPayment}
            />
          </div>
        </div>
        {/* </div> */}
      </section>
      <div id="back-to-top">
        <a className="top" id="top" href="#top">
          {" "}
          <i className="ion-ios-arrow-up"></i>
        </a>
      </div>
    </>
  );
};
