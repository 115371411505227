import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logOut } from "../../redux/user authentication/actionCreator";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMenu, setselectedMenu] = useState("home");
  const [accessToken, setAccessToken] = useState(Cookies.get("access_token"));
  const [currentURL, setCurrentURL] = useState(""); // to store current url

  const { isLoggedIn, userName, isAdmin } = useSelector((state) => {
    return {
      isLoggedIn: state.auth.login,
      userName: state.auth.userName,
      isAdmin: state.auth.isAdmin === undefined ? false : state.auth.isAdmin,
    };
  });

  useEffect(() => {
    setCurrentURL(window.location.pathname);
  }, [window.location.pathname]);

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/");
  };

  useEffect(() => {
    // This code will run whenever the accessToken state changes
    const newAccessToken = Cookies.get("access_token");
    setAccessToken(newAccessToken); // Update the accessToken state
    // You can perform additional actions here when the accessToken changes
    // For example, fetching user data based on the new accessToken.
  }, [accessToken]); // Watch for changes in accessToken

  return (
    <header id="gen-header" class="gen-header-style-1 gen-has-sticky">
      <div class="gen-bottom-header">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg navbar-light">
                <Link class="navbar-brand" to="/">
                  <img
                    class="img-fluid logo"
                    src="assets/images/background/logo.png"
                    alt="streamlab-image"
                    style={{ width: "140px", height: "auto" }}
                  />
                </Link>
                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <div id="gen-menu-contain" class="gen-menu-contain">
                    <ul id="gen-main-menu" class="navbar-nav ml-auto">
                      <li
                        class={`menu-item ${
                          currentURL === "/" ? "active font-weight-bold" : ""
                        }`}
                        onClick={() => setselectedMenu("home")}
                      >
                        <Link to="/">Home</Link>
                      </li>
                      {/* <li
                        class={`menu-item ${
                          selectedMenu === "about"
                            ? "active font-weight-bold"
                            : ""
                        }`}
                        onClick={() => setselectedMenu("about")}
                      >
                        <Link to="/#abouttas">About TAS</Link>
                      </li> */}
                      {[true, "true"].includes(isLoggedIn) && (
                        <li
                          className={`menu-item ${
                            currentURL.includes("/my-videos")
                              ? "active font-weight-bold"
                              : ""
                          }`}
                          onClick={() => setselectedMenu("myVideos")}
                        >
                          <Link to="/my-videos">My Videos</Link>
                        </li>
                      )}
                      <li
                        class={`menu-item ${
                          currentURL.includes("/videos")
                            ? "active font-weight-bold"
                            : ""
                        }`}
                        onClick={() => setselectedMenu("videos")}
                      >
                        <Link to="/videos">Our Videos</Link>
                      </li>
                      {["true", true].includes(isAdmin) ? (
                        <>
                          <li
                            class={`menu-item ${
                              currentURL.includes("/all-users")
                                ? "active font-weight-bold"
                                : ""
                            }`}
                            onClick={() => setselectedMenu("users")}
                          >
                            <Link to="/all-users">Users</Link>
                          </li>
                          <li
                            class={`menu-item ${
                              currentURL.includes("/all-enquiries")
                                ? "active font-weight-bold"
                                : ""
                            }`}
                            onClick={() => setselectedMenu("enquiries")}
                          >
                            <Link to="/all-enquiries">Enquiries</Link>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                      <li
                        class={`menu-item ${
                          currentURL.includes("/notice-board")
                            ? "active font-weight-bold"
                            : ""
                        }`}
                        onClick={() => setselectedMenu("notice-board")}
                      >
                        <Link to="/notice-board">Notice Board</Link>
                      </li>
                      {isLoggedIn ? (
                        <li
                          class="menu-item cursor-pointer"
                          onClick={() => handleLogout()}
                        >
                          Log out
                        </li>
                      ) : (
                        <li
                          class={`menu-item ${
                            currentURL.includes("/contact")
                              ? "active font-weight-bold"
                              : ""
                          }`}
                          onClick={() => setselectedMenu("contact")}
                        >
                          <Link to="/#contact">Contact Us</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div class="gen-header-info-box">
                  <div class="gen-account-holder d-flex align-items-center">
                    <Link
                      to={`${
                        Cookies.get("access_token") ? "/my-videos" : "/login"
                      }`}
                      id="gen-user-btn"
                    >
                      <i class="fa fa-user"></i>
                    </Link>
                    <div className="ms-2">
                      <strong>{userName}</strong>
                    </div>
                  </div>
                  {/* <!-- <div class="gen-btn-container">
                          <a href="video-upload.html" class="gen-button">
                             <div class="gen-button-block">
                                <span class="gen-button-line-left"></span>
                                <span class="gen-button-text"><i
                                      class="fa fa-upload"></i>Upload</span>
                             </div>
                          </a>
                       </div> --> */}
                </div>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i class="fas fa-bars"></i>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
