import Cookies from "js-cookie";
import React from "react";
import { Link } from "react-router-dom";

export const PreLoginPage = () => {
  return (
    <>
      <div class="pt-5"></div>
      <section class="gen-section-padding-3 mt-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="gen-episode-wrapper style-1">
                <div class="row">
                  <div class="col-lg-12">
                    <p class="font-weight-bold fs-2 pb-0 mb-2">
                      The following are the rules of the TAS short film
                    </p>

                    <ul class="list-unstyled fs-6">
                      <li>
                        1. Submitted short film must be shot in the year 2022 and 2023.{" "}
                      </li>
                      <li>
                        2. Duration of the short film should be under 40
                        mins.{" "}
                      </li>
                      <li>
                        3. Entrants can use any camera to shoot the short film.{" "}
                      </li>
                      <li>
                        4. The entrants should not use any sort of copy righted
                        content including the music. Using copyrighted content
                        will directly disqualify the entrants.{" "}
                      </li>
                      <li>
                        5. Entrants can either get the music done by themselves
                        or they can use the YouTube library music (no copyright
                        music) or any other no copyrighted music.
                      </li>
                      <li>
                        6. Entrants must give full details of cast and crew. If
                        film experts are found in cast or crew the entrants will
                        be disqualified.
                      </li>
                    </ul>
                  </div>

                  <div class="col-md-12 text-center mt-5">
                    <Link to={`${Cookies.get("access_token")? "/upload-video":"/login"}`} class="gen-button">
                      <div class="gen-button-block">
                        <span class="gen-button-line-left"></span>
                        <span class="gen-button-text">Continue to {Cookies.get("access_token")? "upload":"Login"}</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
