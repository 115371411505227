/* eslint-disable */
import Cookies from "js-cookie";
import actions from "./actions";
import { DataService } from "../../dataService/dataService";
import { errorToast, successToast } from "../../utils";
// import { ErrorAlert, WarningAlert } from "../../components/utilities/utilities";

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  userRegistrationBegin,
  userRegistrationSuccess,
  userRegistrationErr,
  getAllUsersBegin,
  getAllUsersSuccess,
  getAllUsersErr,
  getAllUsersQueriesBegin,
  getAllUsersQueriesSuccess,
  getAllUsersQueriesErr,
  getAllNoticesBegin,
  getAllNoticesSuccess,
  getAllNoticesErr,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post("/users/user-login", values);
      if (response.status === 200) {
        const { user } = response.data.data;
        const { session } = response.data.data;
        Cookies.set("registration_id", user.registration_id);
        Cookies.set("access_token", session.access_token);
        Cookies.set("logedIn", true);
        Cookies.set("isAdmin", user.type === "admin" ? true : false),
          dispatch(loginSuccess(response.data.data));
        await callback(response.data.data);
        Cookies.set(
          "userName",
          (
            user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
          ).concat(" ", user.last_name)
        );
        // successToast(response.data.message);
      } else {
        errorToast(response.data.message);
        dispatch(loginErr(response.data.errors));
      }
    } catch (err) {
      // ErrorAlert(err);
      dispatch(loginErr(err));
    }
  };
};

const register = (values, callback) => {
  return async (dispatch) => {
    dispatch(userRegistrationBegin());
    try {
      const response = await DataService.post(
        "/users/user-registration",
        values
      );
      if (response.status === 200) {
        dispatch(userRegistrationSuccess(true));
        await callback(response);
        successToast(response.data.message);
      } else {
        dispatch(userRegistrationErr("Registration failed!"));
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(userRegistrationErr(err));
    }
  };
};


const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove("logedIn");
      Cookies.remove("access_token");
      Cookies.remove("userName");
      Cookies.remove("isAdmin");
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
      errorToast(err);
    }
  };
};
const postUserQueries = (values, callback) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post("/users/user-queries", values);
      if (response.status === 200) {
        successToast(response.data.message);
        await callback(response);
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(userRegistrationErr(err));
    }
  };
};

const getAllUsers = (callback) => {
  return async (dispatch) => {
    dispatch(getAllUsersBegin());
    try {
      const response = await DataService.get(`/users/get-users`);
      if (response.status === 200) {
        dispatch(getAllUsersSuccess(response.data.data));
        await callback(response);
      } else {
        dispatch(getAllUsersErr(response.data.message));
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getAllUsersErr(err));
    }
  };
};

const getAllUserQueries = (callback) => {
  return async (dispatch) => {
    dispatch(getAllUsersQueriesBegin());
    try {
      const response = await DataService.get(`/users/get-user-queries`);
      if (response.status === 200) {
        dispatch(getAllUsersQueriesSuccess(response.data.data));
        await callback(response);
      } else {
        dispatch(getAllUsersQueriesErr(response.data.message));
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getAllUsersQueriesErr(err));
    }
  };
};

const updateUserStatus = (registration_id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await DataService.patch(
        `/users/update-user-status/${registration_id}`,
        data
      );
      if (response.status === 200) {
        const { status } = data;

        const currentState = getState().auth.allUsers;
        let updatesArr = currentState.map((item) =>
          item.registration_id === registration_id ? { ...item, status } : item
        );
        dispatch(getAllUsers(updatesArr));
        successToast(response.data.message);
        await callback(response);
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getAllUsersErr(err));
    }
  };
};

const getAllNotices = (registration_id, callback) => {
  return async (dispatch) => {
    dispatch(getAllNoticesBegin());
    try {
      const response = await DataService.get(`/notices/get-all-notices`);
      if (response.status === 200) {
        dispatch(getAllNoticesSuccess(response.data.data));
        await callback(response);
      } else {
        dispatch(getAllNoticesErr(response.data.message));
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getAllNoticesErr(err));
    }
  };
};

const deleteNotice = (noticeId, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await DataService.patch(
        `/notices/delete-notice/${noticeId}`
      );
      if (response.status === 200) {
        const currentState = getState().auth.notices.filter(
          (notice) => notice.status !== "deleted"
        );
        let updatesArr = currentState.map((item) =>
          item.notice_id === noticeId ? { ...item, status: "deleted" } : item
        );
        dispatch(getAllNotices(updatesArr));
        successToast(response.data.message);
        await callback(response);
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getAllNoticesErr(err));
    }
  };
};

const AddNotice = (values, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await DataService.post(`notices/create-notice`, values);
      if (response.status === 200) {
        const currentState = getState().auth.notices.filter(
          (notice) => notice.status !== "deleted"
        );
        const newValues = {
          ...values,
          status: "active",
          notice_id: response.data.noticeId,
          created_date: new Date().toISOString(),
        };

        let updatesArr = currentState.concat(newValues); // or [...currentState, newValues]

        dispatch(getAllNotices(updatesArr));
        successToast(response.data.message);
        await callback(response);
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      errorToast(err);
    }
  };
};
export {
  login,
  logOut,
  register,
  getAllNotices,
  AddNotice,
  deleteNotice,
  getAllUsers,
  updateUserStatus,
  postUserQueries,
  getAllUserQueries
};
