/* eslint-disable */
import Cookies from "js-cookie";
import actions from "./actions";
import { DataService } from "../../dataService/dataService";
import { errorToast, successToast } from "../../utils";
import {
  generateAWSPresignedURL2,
  generateAWSVideosURL,
} from "../../utils/awsServices";

const {
  uploadVideoBegin,
  uploadVideoSuccess,
  uploadVideoErr,
  getIndividualVideoBegin,
  getIndividualVideoSuccess,
  getIndividualVideoErr,
  getIndividualUserVideosBegin,
  getIndividualUserVideosSuccess,
  getIndividualUserVideosErr,
  getAllVideoBegin,
  getAllVideoSuccess,
  getAllVideoErr,
  UpdateAllVideoSuccess,
} = actions;

const uploadVideo = (values, callback) => {
  return async (dispatch) => {
    dispatch(uploadVideoBegin());
    try {
      const response = await DataService.post("/videos/add-video", values);
      if (response.status === 200) {
        successToast(response.data.message);
        dispatch(uploadVideoSuccess(true));
        await callback(response.data);
      } else {
        errorToast(response.data.message);
        dispatch(uploadVideoErr(response.data.errors));
      }
    } catch (err) {
      // ErrorAlert(err);
      dispatch(uploadVideoErr(err));
    }
  };
};

const getIndividualUserVideo = (registration_id, callback) => {
  return async (dispatch) => {
    dispatch(getIndividualUserVideosBegin());
    try {
      const response = await DataService.get(
        `/videos/get-individual-user-video/${registration_id}`
      );
      if (response.status === 200) {
        const aws = await generateAWSPresignedURL2(response.data.data);
        dispatch(getIndividualUserVideosSuccess(aws));
        // successToast(response.data.message);
        await callback(response);
      } else {
        dispatch(getIndividualUserVideosErr(response.data.message));
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getIndividualVideoErr(err));
    }
  };
};

const getIndividualVideo = (video_id, callback) => {
  return async (dispatch) => {
    dispatch(getIndividualVideoBegin());
    try {
      const response = await DataService.get(
        `/videos/get-individual-video/${video_id}`
      );
      if (response.status === 200) {
        const aws = await generateAWSPresignedURL2(response.data.data[0]);
        dispatch(getIndividualVideoSuccess(aws));
        // successToast(response.data.message);
        await callback(response);
      } else {
        dispatch(getIndividualVideoErr(response.data.message));
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getIndividualVideoErr(err));
    }
  };
};

const updateIndividualVideo = (video_id, values, callback) => {
  return async (dispatch) => {
    dispatch(getIndividualVideoBegin());
    try {
      const response = await DataService.patch(
        `/videos/update-individual-video/${video_id}`,
        values
      );
      if (response.status === 200) {
        dispatch(uploadVideoSuccess(true));
        successToast(response.data.message);
        await callback(response);
      } else {
        dispatch(getIndividualVideoErr(response.data.message));
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getIndividualVideoErr(err));
    }
  };
};

const uploadIndividualVideo = (video_id, values, callback) => {
  return async () => {
    try {
      const response = await DataService.patch(
        `/videos/upload-individual-video/${video_id}`,
        values
      );
      if (response.status === 200) {
        successToast(response.data.message);
        await callback(response);
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      errorToast(err);
    }
  };
};

const uploadIndividualVideoPaymentDetails = (video_id, values, callback) => {
  return async () => {
    try {
      const response = await DataService.patch(
        `/videos/add-payment-details/${video_id}`,
        values
      );
      if (response.status === 200) {
        successToast(response.data.message);
        await callback(response);
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      errorToast(err);
    }
  };
};

const getAllVideo = (registration_id, callback) => {
  return async (dispatch) => {
    dispatch(getAllVideoBegin());
    try {
      const response = await DataService.get(`/videos/get-allvideos`);
      if (response.status === 200) {
        const aws = await generateAWSVideosURL(response.data.data);
        dispatch(getAllVideoSuccess(aws));
        await callback(response);
      } else {
        dispatch(getAllVideoErr(response.data.message));
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getAllVideoErr(err));
    }
  };
};

const UpdateAllVideo = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch(UpdateAllVideoSuccess(data));
    } catch (err) {
      console.log("err", err);
    }
  };
};

const UpdatePaymentStatus = (registration_id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await DataService.patch(
        `/videos/add-payment-details/${registration_id}`,
        data
      );
      if (response.status === 200) {
        const { payment_status } = data;

        const currentState = getState().videos.allVideos;
        let updatesArr = currentState.map((item) =>
          item.registration_id === registration_id ? { ...item, payment_status } : item
        );
        dispatch(getAllVideoSuccess(updatesArr));
        successToast(response.data.message);
        await callback(response);
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getAllVideoErr(err));
    }
  };
};

const UpdateVideoStatus = (video_id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await DataService.patch(
        `/videos/update-video-status/${video_id}`,
        data
      );
      if (response.status === 200) {
        const { status } = data;

        const currentState = getState().videos.allVideos;
        let updatesArr = currentState.map((item) =>
          item.video_id === video_id ? { ...item, status } : item
        );
        dispatch(getAllVideoSuccess(updatesArr));
        successToast(response.data.message);

        await callback(response);
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      dispatch(getAllVideoErr(err));
    }
  };
};

export {
  uploadVideo,
  getIndividualUserVideo,
  getIndividualVideo,
  getAllVideo,
  UpdateAllVideo,
  UpdateVideoStatus,
  updateIndividualVideo,
  uploadIndividualVideo,
  UpdatePaymentStatus,
  uploadIndividualVideoPaymentDetails,
};
