import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import {
  UpdateAllVideo,
  getAllVideo,
} from "../../../redux/videos/actionCreator";
import AWS from "aws-sdk";
import ReactPlayer from "react-player";

export const GlobalVideosPage = () => {
  const dispatch = useDispatch();
  const {
    REACT_APP_BUCKET_NAME,
    REACT_APP_ACCESS_KEY,
    REACT_APP_SECRET_KEY,
    REACT_APP_REGION,
    REACT_APP_EXPIRES,
  } = process.env;

  useEffect(() => {
    const registrationId = Cookies.get("registration_id");
    dispatch(getAllVideo(registrationId));
  }, []);

  const { allVideos } = useSelector((states) => {
    return {
      allVideos: states.videos.allVideos.filter(
        (video) => video.status === "active"
      ),
    };
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (allVideos) {
  //       await generateAWSDocumentURL(allVideos);
  //     }
  //   };

  //   fetchData();
  // }, [allVideos]);

  /**  ********************************************
    Fetch videos details from s3 bucket 
 ********************************************  */
  const generateAWSDocumentURL = async (arr) => {
    let temp = [];
    await arr.map(async (nObj, index) => {
      temp.push(
        new Promise((resolve, reject) => {
          const { file_url } = nObj;
          let key1 = file_url.split(".com/");
          if (key1[0].includes(REACT_APP_BUCKET_NAME)) {
            const S3 = new AWS.S3();
            AWS.config.update({
              accessKeyId: REACT_APP_ACCESS_KEY,
              secretAccessKey: REACT_APP_SECRET_KEY,
              region: REACT_APP_REGION,
            });
            S3.getSignedUrl(
              "getObject",
              {
                Bucket: REACT_APP_BUCKET_NAME,
                Expires: parseInt(REACT_APP_EXPIRES),
                Key: key1[1],
              },
              async (err, video) => {
                if (video == undefined) {
                  nObj["videoLink"] = arr.file_url;
                  resolve();
                } else {
                  nObj["videoLink"] = video;
                  resolve();
                }
              }
            );
          } else {
            nObj["videoLink"] = null;
            resolve();
          }
        })
      );
      Promise.all(temp).then((res) => {
        // setAircraftData(arr);
        UpdateAllVideo(arr);
      });
    });
  };

  return (
    <>
      <div className="pt-5"></div>
      <section className="gen-section-padding-3 mt-5" id="top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              {allVideos.map((video, index) => {
                return (
                  <div className="gen-episode-wrapper style-1">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="gen-video-holder">
                          {video.youtube_url ? (
                            <iframe
                              width="560"
                              height="315"
                              src={video.youtube_url}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          ) : (
                            <ReactPlayer
                              controls
                              width="100%"
                              height="100%"
                              url={video.videoLink}
                            />
                          )}
                          {console.log("video.youtube_url", video.youtube_url)}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-episode">
                          <div className="gen-single-tv-show-info">
                            <h2 className="gen-title">{video.title}</h2>

                            <p>{video.video_synopsis}</p>
                            <div className="gen-after-excerpt">
                              <div className="gen-extra-data">
                                <ul>
                                  {/* <li>
                                <span>Language :</span>
                                <span>English</span>
                              </li>
                              <li>
                                <span>Subtitles :</span>
                                <span>English</span>
                              </li>
                              <li>
                                <span>Audio Languages :</span>
                                <span>English</span>
                              </li> */}
                                  <li>
                                    <span>Genre :</span>
                                    <span>
                                      <a href="action.html">{video.genre} </a>
                                    </span>
                                    {/* <span>
                                  <a href="adventure.html">Documentary</a>
                                </span> */}
                                  </li>
                                  <li>
                                    <span>Run Time :</span>
                                    <span>{video.duration}</span>
                                  </li>
                                  <li>
                                    <span>Cast :</span>
                                    <span>{video.cast}</span>
                                  </li>
                                  <li>
                                    <span>Crew :</span>
                                    <span>{video.crew}</span>
                                  </li>
                                  <li>
                                    <span>Release Date :</span>
                                    <span>
                                      {moment(video.created_date).format(
                                        "DD MMM, yyyy"
                                      )}
                                    </span>
                                  </li>
                                  
                                  <li>
                                    <span>Uploaded by :</span>
                                    <span>{`${video.first_name} ${video.last_name} (${video.registration_id})`}</span>
                                  </li>
                                </ul>
                              </div>

                              {/* <div className="gen-socail-share">
                                <h4 className="align-self-center">
                                  Social Share :
                                </h4>
                                <ul className="social-inner">
                                  <li>
                                    <a href="#" className="facebook">
                                      <i className="fab fa-facebook-f"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" className="facebook">
                                      <i className="fab fa-instagram"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" className="facebook">
                                      <i className="fab fa-twitter"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <div id="back-to-top">
        <a className="top" id="top" href="#top">
          {" "}
          <i className="ion-ios-arrow-up"></i>
        </a>
      </div>
    </>
  );
};
