import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { VideosPage } from "./pages/videosPage";
// import { BrowserRouter as Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MainPage from "./pages/mainPage";
import { PreLoginPage } from "./pages/loginPage/preLoginPage";
import { Login } from "./pages/loginPage/login";
import { Register } from "./pages/loginPage/register";
import { VideoUploadPage } from "./pages/videoUploadPage";
import { GlobalVideosPage } from "./pages/videosPage/allVideos";
import { VideosControlPage } from "./pages/admin";
import { useSelector } from "react-redux";
import { IndividualVideoPage } from "./pages/videosPage/individualVideoPage";
import { NoticeBoard } from "./pages/noticeBoard";
import { VideoEditPage } from "./pages/videoUploadPage/videoEditPage";
import { Users } from "./pages/users";
import { UserQueries } from "./pages/userQueries";

function App() {
  const { isAdmin } = useSelector((states) => {
    return {
      isAdmin: states.auth.isAdmin === undefined ? false : states.auth.isAdmin,
    };
  });
  return (
    <>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            {['true', true].includes(isAdmin) ? (
              <Route path="/" element={<VideosControlPage />} />
            ) : (
              <Route path="/" element={<MainPage />} />
            )}
            <Route path="/upload-video" element={<VideoUploadPage />} />
            <Route path="/notice-board" element={<NoticeBoard />} />
            <Route path="/upload-video/:video_id" element={<VideoUploadPage />} />
            <Route path="/my-videos" element={<VideosPage />} />
            <Route path="/view-videos/:video_id" element={<IndividualVideoPage />} />
            <Route path="/videos" element={<GlobalVideosPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/pre-login" element={<PreLoginPage />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/edit-video" element={<VideoEditPage />} />
            <Route path="/all-users" element={<Users />} />
            <Route path="/all-enquiries" element={<UserQueries />} />
          </Routes>
          <Footer />
        </div>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
